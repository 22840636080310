import { ReactComponent as SadEmoji } from '../components/Modal/assets/sadEmoji.svg';
import { ReactComponent as SmileEmoji } from '../components/Modal/assets/smileEmoji.svg';
import { ReactComponent as HeartEmoji } from '../components/Modal/assets/heartEmoji.svg';
import { ReactComponent as FingerUpEmoji } from '../components/Modal/assets/fingerUpEmoji.svg';
import { ReactComponent as LookEmoji } from '../components/Modal/assets/lookEmoji.svg';
import { ReactComponent as StopIcon } from '../components/TeacherControlBlock/assets/stopIcon.svg';
import { ReactComponent as AttentionIcon } from '../components/TeacherControlBlock/assets/attention.svg';
import { ReactComponent as RingIcon } from '../components/TeacherControlBlock/assets/ringIcon.svg';

const reactions = {
  smileEmoji: {
    name: 'smileEmoji',
    icon: SmileEmoji,
    ruName: 'нравится',
  },
  sadEmoji: {
    name: 'sadEmoji',
    icon: SadEmoji,
    ruName: 'не нравится',
  },
  fingerUpEmoji: {
    name: 'fingerUpEmoji',
    icon: FingerUpEmoji,
    ruName: 'группа',
  },
  heartEmoji: {
    name: 'heartEmoji',
    icon: HeartEmoji,
    ruName: 'сердце',
  },
  lookEmoji: {
    name: 'lookEmoji',
    icon: LookEmoji,
    ruName: 'заниматься',
  },
  attention: {
    name: 'attention',
    icon: AttentionIcon,
  },
  stop: {
    name: 'stop',
    icon: StopIcon,
  },
  ring: {
    name: 'ring',
    icon: RingIcon,
  },
};

export default reactions;
