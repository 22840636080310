import apiURLs from '../../constants/apiURLs';

class StudentsEndpoint {
  constructor(axios, setBearer, removeBearer) {
    this.axios = axios;
    this.setBearer = setBearer;
    this.removeBearer = removeBearer;
  }

  getStudentsList = async (filter, page) => {
    const formatterFilter = { ...filter };

    if (formatterFilter.name === '') {
      delete formatterFilter.name;
    }
    if (formatterFilter.adult_name === '') {
      delete formatterFilter.adult_name;
    }
    if (!formatterFilter?.education_classes?.length) {
      delete formatterFilter.education_classes;
    } else {
      const newClasses = formatterFilter.education_classes.map(item => item.name || item.label);
      formatterFilter.education_classes = newClasses;
    }
    if (!formatterFilter?.education_formats?.length) {
      delete formatterFilter.education_formats;
    } else {
      const newFormats = formatterFilter.education_formats.map(item => item.label);
      formatterFilter.education_formats = newFormats;
    }
    if (formatterFilter.education_event_type_id === '') {
      delete formatterFilter.education_event_type_id;
    }
    if (formatterFilter.education_event_id === '' || formatterFilter.education_event_id === null) {
      delete formatterFilter.education_event_id;
    }
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getStudentsList,
        params: { filter: formatterFilter, page, per_page: 8 },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getStudentsFilters = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getStudentsFilters,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  downloadStudentList = async filter => {
    const formatterFilter = { ...filter };
    if (formatterFilter.name === '') {
      delete formatterFilter.name;
    }
    if (formatterFilter.education_classes.length === 0) {
      delete formatterFilter.education_classes;
    } else {
      const newClasses = formatterFilter.education_classes.map(item => item.label);
      formatterFilter.education_classes = newClasses;
    }
    if (formatterFilter.education_formats.length === 0) {
      delete formatterFilter.education_formats;
    } else {
      const newFormats = formatterFilter.education_formats.map(item => item.label);
      formatterFilter.education_formats = newFormats;
    }
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.downloadStudentList,
        responseType: 'blob',
        params: { filter: formatterFilter },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}
export default StudentsEndpoint;
