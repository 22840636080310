function noButtonPressed(payload) {
  const { isTeacher } = this.roleInLesson;
  const { setReaction } = this.reactionStore;
  if (isTeacher) {
    setReaction({
      type: 'noButton',
      text: 'Ответил(а) НЕТ',
      name: payload.lesson.student.student_name,
    });
  }
}

export default noButtonPressed;
