import { makeAutoObservable } from 'mobx';

class ChatStore {
  constructor() {
    this.isChatOpen = false;
    this.messages = [];
    this.unreadCounter = +localStorage.getItem('amount_unread') || 0;
    this.selectedMessageIds = [];
    this.chatPage = 0;
    makeAutoObservable(this);
  }

  openChat = () => {
    this.isChatOpen = !this.isChatOpen;
    this.chatPage = 0;
  };

  setMessages(messages, shouldDelete) {
    if (this.chatPage > 0 && !shouldDelete) {
      this.messages = [...this.messages, ...messages];
    } else {
      this.messages = messages;
    }
  }

  resetMessages = () => {
    this.messages = [];
  }

  updateCounter = (number) => {
    this.unreadCounter = number;
    localStorage.setItem('amount_unread', number);
  }

  get reverseMessages() {
    const messages = [...this.messages].reverse();
    return messages;
  }

  addMessages(messages) {
    const [...oldMessages] = this.messages;
    this.messages = [...oldMessages, ...messages].sort((message1, message2) => {
      const { order: order1 } = message1;
      const { order: order2 } = message2;
      if (order1 > order2) {
        return -1;
      }
      if (order1 < order2) {
        return 1;
      }
      return 0;
    });
  }

  addMessage(message) {
    if (this.messages) {
      const [...messages] = this.messages;
      messages.unshift(message);
      this.messages = messages;
    } else {
      this.messages = [message];
    }
  }

  deleteMessage(messageId) {
    this.messages = this.messages.filter(message => message.id !== messageId);
  }

  clearAll = () => {
    this.messages = [];
    this.selectedMessageIds = [];
  };

  setChatPage = value => {
    this.chatPage = value;
  };

  nextPage = () => {
    this.chatPage += 1;
  };
}

export default ChatStore;
