function secondThemeButtonReaction(payload) {
  const { isTeacher } = this.roleInLesson;
  const { setReaction } = this.reactionStore;
  const { secondButton } = this.buttonsStore;
  if (isTeacher) {
    setReaction({
      type: 'secondButton',
      text: secondButton.name,
      name: payload.lesson.student.student_name,
    });
  }
}

export default secondThemeButtonReaction;
