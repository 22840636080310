import { toast } from 'react-toastify';
import history from './history';
import navigation from '../../constants/navigation';
import { authApi, profileApi } from '../../api/apiEndpoints';
import getSearchParams from '../searchParams/getSearchParams';
import localStorage from '../localStorage';
import { Lesson } from '../../Lesson/Lesson';

async function signUpConfirm() {
  const token = getSearchParams('token');
  try {
    return (await authApi.confirm(token)) && true;
  } catch (e) {
    const { message } = e;
    toast.error(message);
    return false;
  }
}

async function restoreAccountConfirm() {
  const token = getSearchParams('token');
  try {
    return (await profileApi.restoreAccount(token)) && true;
  } catch (e) {
    const { message } = e;
    toast.error(message);
    return false;
  }
}

async function defaultRedirection() {
  const lessonPathRegular = new RegExp(/^\/lesson/);
  const authConfirmationRegular = new RegExp(/^\/registration-confirmation/);
  const recoveryPasswordRegular = new RegExp(/^\/recovery-password/);
  const restoreAccountRegular = new RegExp(/^\/restore-account/);
  const changeEmailRegular = new RegExp(/^\/change-email-confirmation/);
  const editMentorRegular = new RegExp(/^\/edit-mentor/);
  const purchaseFormsRegular = new RegExp(/\/purchase/);
  const studentTimeSheetFromAdminRegular = new RegExp(/\/time-sheet\/student/);
  const teacherTimeSheetFromAdminRegular = new RegExp(/\/time-sheet\/mentor/);
  const currentPath = history.location.pathname;

  if (authConfirmationRegular.test(currentPath)) {
    const isAuthConfirmSuccess = await signUpConfirm();
    if (isAuthConfirmSuccess) {
      if (localStorage.lessonAuthorizationData) {
        const lesson = new Lesson();
        await lesson.connect();
      } else {
        history.push({ pathname: navigation.successSignUp.path, state: 'success' });
      }
    } else {
      history.push(navigation.home.path);
    }
  } else if (recoveryPasswordRegular.test(currentPath)) {
    const token = getSearchParams('token');
    history.push({ pathname: navigation.recoveryPassword.path, token });
  } else if (restoreAccountRegular.test(currentPath)) {
    const isRestoreAccountConfirmSuccess = await restoreAccountConfirm();
    if (isRestoreAccountConfirmSuccess) {
      toast.info('Ваш аккаунт восстановлен, пожалуйста, авторизуйтесь');
      history.push(navigation.authorization.path);
    }
  } else if (changeEmailRegular.test(currentPath)) {
    const token = getSearchParams('token');
    history.push({ pathname: navigation.changeEmail.path, token });
  } else if (lessonPathRegular.test(currentPath)) {
    const lesson = new Lesson();
    await lesson.connect();
  } else if (editMentorRegular.test(currentPath)) {
    if (!localStorage.accessToken) {
      const id = history.location.pathname.split('/')[2];
      localStorage._setter('editMentor', Number(id));
      history.push(navigation.authorization.path);
    } else {
      history.push(currentPath);
    }
  } else if (!localStorage.accessToken && currentPath !== navigation.authorization.path) {
    if (purchaseFormsRegular.test(currentPath)) {
      localStorage._setter('purchaseRedirect', true);
    } else if (
      studentTimeSheetFromAdminRegular.test(currentPath) ||
      teacherTimeSheetFromAdminRegular.test(currentPath)
    ) {
      localStorage._setter('userTimeSheet', currentPath);
    }
    history.push(navigation.authorization.path);
  } else if (localStorage.accessToken && currentPath === navigation.authorization.path) {
    history.push(navigation.home.path);
  } else if (localStorage.accessToken && currentPath === navigation.successSignUp.path) {
    history.push(navigation.home.path);
  }
}

export default defaultRedirection;
