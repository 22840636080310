export default function chatSuccessSocketRequestCallback(action, data) {
  if (action === this.chatActions.getMessagesChat) {
    console.log('case getMessagesChat', data);
    const { chat } = data.payload.lesson;
    const adaptedMessages = chat.messages.data.map(message =>
      this.messageAdapter.setMessage({ chatObj: { id: chat.id, message } }),
    );
    this.chatStore.setMessages(adaptedMessages);
    localStorage.setItem('amount_unread', chat.messages.meta.amount_unread_messages);
    localStorage.setItem('last_read_order', chat.messages.meta.last_readed_order);
    if (!localStorage.getItem('unread_messages')) {
      localStorage.setItem('unread_messages', '[]');
    }
    const unreadAmount = +localStorage.getItem('amount_unread');
    this.chatStore.updateCounter(unreadAmount);
  }
}
