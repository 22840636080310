import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import closeIcon from './assets/closeIcon.svg';

export default function Modal({ onClose, title, children, size }) {
  return createPortal(
    <div>
      <div>
        <div className="modalOverlay">
          <div className={`modalWindow modalWindow_${size}`}>
            <div className="modalHeader">
              <p className="modalTitle" dangerouslySetInnerHTML={{ __html: title }} />
              {onClose && (
                <button className="closeBtn" type="button" onClick={onClose}>
                  <img src={closeIcon} alt="closeICon" />
                </button>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('portal'),
  );
}

Modal.defaultProps = {
  onClose: undefined,
};

Modal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  size: PropTypes.oneOf([
    'standart',
    'small',
    'very-small',
    'reaction',
    'attention',
    'request',
    'reload-app',
    'leave-page',
    'cropper',
    'education-forms',
    'my-progress-upload',
    'make-order',
    'join-lesson',
    'create-folder',
    'timesheet',
  ]),
};

Modal.defaultProps = {
  size: 'standart',
};
