function toiletNeededButton(payload) {
  const { isTeacher } = this.roleInLesson;
  const { setReaction } = this.reactionStore;
  if (isTeacher) {
    setReaction({
      type: 'toilet',
      text: 'Хочет в туалет',
      name: payload.lesson.student.studentName,
    });
  }
}

export default toiletNeededButton;
