import apiURLs from '../../constants/apiURLs';

class MyProgressEndpoints {
  constructor(axios, setBearer, removeBearer) {
    this.axios = axios;
    this.setBearer = setBearer;
    this.removeBearer = removeBearer;
  }

  getMyProgress = async (userId, materialId, params) => {
    if (!params.name) {
      delete params.name;
    }
    if (params.teacher_ids.length === 0) {
      delete params.teacher_ids;
    }
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getMyProgress(userId, materialId),
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getMyNestedProgress = async (userId, materialId, folderId, params) => {
    if (!params.name) {
      delete params.name;
    }
    if (params.teacher_ids.length === 0) {
      delete params.teacher_ids;
    }
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getMyNestedProgress(userId, materialId, folderId),
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getResourcesFile = async id => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getResourcesFile(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  deleteResourceFile = async id => {
    try {
      const response = await this.axios({
        method: 'DELETE',
        url: apiURLs.getResourcesFile(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getMentors = async (filter, page) => {
    const formatterFilter = { ...filter };
    if (formatterFilter.name === '') {
      delete formatterFilter.name;
    }
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getMentors,
        params: { filter: formatterFilter, page, per_page: 10 },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  createStudentResource = async data => {
    if (!data.parent_id) {
      delete data.parent_id;
    }
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.createStudentResource,
        data,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  deleteStudentResource = async id => {
    try {
      const response = await this.axios({
        method: 'DELETE',
        url: apiURLs.deleteStudentResource(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getDownloadLinkMyProgress = async id => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getDownloadLinkMyProgress(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  createFolder = async data => {
    if (!data.parent_id) {
      delete data.parent_id;
    }

    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.createFolder,
        data,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  deleteFolder = async id => {
    try {
      const response = await this.axios({
        method: 'DELETE',
        url: apiURLs.deleteFolder(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  editFolder = async (data, id) => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.deleteFolder(id),
        data: { name: data.name },
        params: {
          _method: 'PUT',
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}
export default MyProgressEndpoints;
