export default function chatFailureSocketRequestCallback(action) {
  switch (action) {
    case this.chatActions.getMessagesChat:
      console.log('case getMessagesChat');
      break;
    case this.chatActions.sendMessageChat:
      console.log('case sendMessageChat');
      break;
    case this.chatActions.deleteMessageChat:
      console.log('case deleteMessageChat');
      break;
    // no default
  }
}
