async function getRoomInfo(room) {
  const { is_show_theme_buttons: isGlobalTopicButtons, otherPeers } = room;

  this.buttonsStore.setGlobalTopicButtonState(isGlobalTopicButtons);
  if (isGlobalTopicButtons) {
    this.buttonsStore.displayTopicButtons(isGlobalTopicButtons);
  }

  otherPeers.forEach(peer => {
    const { peerId, is_show_theme_buttons: isStudentTopicButtons } = peer;
    this.buttonsStore.setStudentsTopicButton(peerId, isStudentTopicButtons);
    if (isStudentTopicButtons) {
      this.buttonsStore.displayTopicButtons(isStudentTopicButtons);
    }
    this.buttonsStore.setStudentsTopicButton(peerId, isStudentTopicButtons);
  });
}

export default getRoomInfo;
