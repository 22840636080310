import { mediaTypes } from '../../constants/mediaTypes';

class MediaError extends Error {
  constructor(domExceptionErrorName, mediaType) {
    super('');
    this.name = this.constructor.name;
    this.type = domExceptionErrorName;
    this.domExceptionErrorNames = {
      abortError: 'AbortError',
      notAllowedError: 'NotAllowedError',
      notFoundError: 'NotFoundError',
      notReadableError: 'NotReadableError',
      overconstrainedError: 'OverconstrainedError',
      securityError: 'SecurityError',
      typeError: 'TypeError',
    };
    this._setMediaType(mediaType);
    this._setMessage(domExceptionErrorName);
  }

  _setMediaType(mediaType) {
    if (
      mediaType === mediaTypes.audioType ||
      mediaType === mediaTypes.videoType ||
      mediaType === mediaTypes.screenType
    ) {
      this.mediaType = mediaType;
    } else {
      this.mediaType = 'Unknown';
    }
  }

  _setMessage(domExceptionErrorName) {
    const {
      abortError,
      notAllowedError,
      notFoundError,
      notReadableError,
      overconstrainedError,
      securityError,
      typeError,
    } = this.domExceptionErrorNames;
    let message = '';
    const defaultMessage = 'Ошибка получения доступа к камере и/или микрофону';
    switch (domExceptionErrorName) {
      case abortError:
        message = 'Технические неполадки со считавающими медиаустройствами';
        break;
      case notAllowedError:
        message = 'Пользователем запрещен доступ к медиаустройству';
        break;
      case notFoundError:
        message = 'Не удалось найти медиаустройство';
        break;
      case notReadableError:
        message = 'Не удалось получить данный с медиаустройства';
        break;
      case overconstrainedError:
        message = 'Ни одно из запрашиваемых требований не соответствует имеющимся медиаустройствам';
        break;
      case securityError:
        message = 'Ошибка бозопасности доступа к медиаустройствам';
        break;
      case typeError:
        message = defaultMessage;
        break;
      default:
        message = defaultMessage;
    }

    this.message = message;
  }
}

export default MediaError;
