import joinLesson from './joinLesson';
import getLessonInfo from './getLessonInfo';
import getRoomInfo from './getRoomInfo';

async function successSocketRequest(action, data) {
  const joinLessonFunction = joinLesson.bind(this);
  const getLessonInfoFunction = getLessonInfo.bind(this);
  const getRoomInfoFunction = getRoomInfo.bind(this);
  const { lesson, room } = data.payload;
  switch (action) {
    case this.actions.joinLesson: {
      await joinLessonFunction(lesson);
      break;
    }
    case this.actions.getLessonInfo: {
      await getLessonInfoFunction(lesson);
      break;
    }
    case this.actions.getRoomInfo: {
      getRoomInfoFunction(room);
    }
    // no default
  }
}

export default successSocketRequest;
