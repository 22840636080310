import mediaTypeAdapter from '../../../../utils/mediaTypeAdapter';
import checkIsPeerIdRemotePeerId from '../../../../utils/checkIsPeerIdRemotePeerId';
import scalabilityLayers from '../../../../constants/scalabilityLayers';
import { mediaTypes } from '../../../../constants/mediaTypes';
import userStartedStreamEventAdapter from '../adapters/userStartedStreamEventAdapter';

function userStartedStream(action, payload) {
  let adaptedPayload = { ...payload };
  if (!adaptedPayload.adapted) {
    adaptedPayload = userStartedStreamEventAdapter(payload);
  }

  const { peerId, producer } = adaptedPayload;

  if (!this.isReadyToHandleEvents) {
    const event = {
      eventName: action,
      pairID: producer.streamType,
      eventData: { action, payload: adaptedPayload },
      peerID: peerId,
    };
    this.socket.rawEvents.push(event);
    return;
  }

  const isRemotePeerId = checkIsPeerIdRemotePeerId(this.peerInfo, peerId);

  if (isRemotePeerId) {
    const isTeacher = this.teacherPeerId === peerId;
    const { roomId } = this.roomInfo;
    const mediaType = mediaTypeAdapter(producer.streamType);
    const producerInfo = {
      producerId: producer.producerId,
      mediaType,
    };

    let scalabilityLevel;

    this.queue.pushTask(peerId, async () => {
      if (mediaType === mediaTypes.videoType || mediaType === mediaTypes.screenType) {
        scalabilityLevel = this.isAllClassMode ? scalabilityLayers.low : scalabilityLayers.high;
      }

      await this.mediasoup.consume(roomId, producerInfo, peerId, isTeacher, scalabilityLevel);
      this.queue.resumeTaskExecuting(peerId);
    });
  }
}

export default userStartedStream;
