import { toast } from 'react-toastify';
import reactions from '../../../constants/reactions';

function stopNotification() {
  const { openReaction } = this.modalWindowsStore;
  const { isStudent } = this.roleInLesson;

  if (isStudent) {
    openReaction(true, reactions.stop.name);
  } else {
    toast.info('Вы отправили сигнал «стоп»');
  }
}

export default stopNotification;
