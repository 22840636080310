function firstThemeButtonReaction(payload) {
  const { isTeacher } = this.roleInLesson;
  const { setReaction } = this.reactionStore;
  const { firstButton } = this.buttonsStore;
  if (isTeacher) {
    setReaction({
      type: 'firstButton',
      text: firstButton.name,
      name: payload.lesson.student.student_name,
    });
  }
}

export default firstThemeButtonReaction;
