/* eslint-disable import/no-cycle */
import api from '.';

const authApi = api.endpoints.auth;
const trainingApi = api.endpoints.training;
const chatApi = api.endpoints.chat;
const profileApi = api.endpoints.profile;
const productsApi = api.endpoints.products;
const subjectsApi = api.endpoints.subjects;
const materialsApi = api.endpoints.materials;
const myProgressApi = api.endpoints.myProgress;
const studentsApi = api.endpoints.students;
const educationEventsApi = api.endpoints.educationEvents;

// eslint-disable-next-line import/prefer-default-export
export {
  authApi,
  trainingApi,
  chatApi,
  profileApi,
  productsApi,
  subjectsApi,
  materialsApi,
  myProgressApi,
  studentsApi,
  educationEventsApi,
};
