import apiURLs from '../../constants/apiURLs';

class MaterialsEndpoints {
  constructor(axios, setBearer, removeBearer) {
    this.axios = axios;
    this.setBearer = setBearer;
    this.removeBearer = removeBearer;
  }

  getMaterials = async (userId, materialId, params) => {
    if (!params.name) {
      delete params.name;
    }
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getMaterials(userId, materialId),
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getNestedMaterials = async (userId, materialId, folderId, params) => {
    if (!params.name) {
      delete params.name;
    }
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getNestedMaterials(userId, materialId, folderId),
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getVideoInMaterials = async (userId, subjectId, recourceId) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getVideoInMaterials(userId, subjectId, recourceId),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  downloadMaterial = async id => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.downloadMaterial(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  checkHomeWork = async id => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.checkHomeWork(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  unCheckHomeWork = async id => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.unCheckHomeWork(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}
export default MaterialsEndpoints;
