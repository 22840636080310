import { toast } from 'react-toastify';
import reactions from '../../../constants/reactions';

function emojiEvent(payload) {
  const { emoji_type: emojiType } = payload.lesson;
  const { isStudent } = this.roleInLesson;
  const { openReaction } = this.modalWindowsStore;

  if (isStudent) {
    openReaction(true, emojiType);
  } else {
    toast.info(`Вы отправили эмодзи «${reactions[emojiType].ruName}»`);
  }
}

export default emojiEvent;
