function yesButtonPressed(payload) {
  const { isTeacher } = this.roleInLesson;
  const { setReaction } = this.reactionStore;
  if (isTeacher) {
    setReaction({
      type: 'yesButton',
      text: 'Ответил(а) ДА',
      name: payload.lesson.student.student_name,
    });
  }
}

export default yesButtonPressed;
