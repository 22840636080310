import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

function Button(props) {
  const {
    classname,
    color,
    icon,
    text,
    disabled,
    isSubmitType,
    justifyContent,
    onClick,
    unreadCounter,
    showCounter,
  } = props;

  const buttonClassName = 'button';

  const buttonStyle = useMemo(
    () =>
      cn(
        buttonClassName,
        [`${buttonClassName}_${color}`],
        { [`${buttonClassName}_${color}_disabled`]: disabled },
        `${buttonClassName}_justify-content-${justifyContent}`,
        classname,
      ),
    [disabled, justifyContent, color, classname],
  );

  const click = e => {
    if (onClick) {
      onClick(e);
    }
  };

  const rendererIcon = useMemo(() => {
    if (typeof icon === 'string') {
      return <img className="button__icon-back" src={icon} alt="icon" />;
    }
    return icon;
  }, [icon]);

  const counter = useMemo(
    () => (unreadCounter > 0 ? 'unread-counter active' : 'unread-counter'),
    [unreadCounter],
  );

  return (
    <button
      type={isSubmitType ? 'submit' : 'button'}
      className={buttonStyle}
      onClick={click}
      disabled={disabled}
    >
      {text}
      {icon && <div className={`${buttonClassName}__icon`}>{rendererIcon}</div>}
      {showCounter && <span className={counter}>{unreadCounter}</span>}
    </button>
  );
}

Button.propTypes = {
  classname: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'navigation',
    'white',
    'red',
    'green',
    'video-framed',
  ]),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isSubmitType: PropTypes.bool,
  showCounter: PropTypes.bool,
  justifyContent: PropTypes.oneOf(['between', 'center']),
  onClick: PropTypes.func,
  unreadCounter: PropTypes.number,
};

Button.defaultProps = {
  classname: '',
  color: 'primary',
  icon: null,
  text: '',
  disabled: false,
  isSubmitType: false,
  showCounter: false,
  justifyContent: 'between',
  onClick: undefined,
  unreadCounter: 0,
};

export default Button;
