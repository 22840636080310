class ListeningVariable {
  constructor(initialValue) {
    this._value = initialValue;
    this._listenerCallbacks = new Map();
    this._availableTypes = null;
    this._initAvailableTypes();

    if (!this._isCorrectTypeCheck(typeof initialValue)) {
      throw new Error(`InitialValue must be a ${this._availableTypes.getSeparatedString(' | ')}`);
    }
  }

  addListener(name, callback, options = { executeIfNoChange: true }) {
    if (this._listenerCallbacks.has(name)) {
      throw new Error('Callback name is already exist');
    }
    this._listenerCallbacks.set(name, { callback, options });
  }

  removeListener(name) {
    if (this._listenerCallbacks.has(name)) {
      this._listenerCallbacks.delete(name);
      return true;
    }
    return false;
  }

  set value(value) {
    const newValueType = typeof value;
    if (this._isCorrectTypeCheck(newValueType)) {
      const valueType = typeof this._value;
      if (valueType !== newValueType) {
        throw new Error(`Value type must be a ${valueType}`);
      }
      const isChanged = this._value !== value;
      this._value = value;
      this._executeListenerCallbacks(value, isChanged);
    } else {
      throw new Error(`Value must be one of ${this._availableTypes.getSeparatedString(' | ')}`);
    }
  }

  get value() {
    return this._value;
  }

  _executeListenerCallbacks(newStatusValue, isValueChanged) {
    Array.from(this._listenerCallbacks).forEach(([, { callback, options }]) => {
      const { executeIfNoChange } = options;
      if (!isValueChanged && !executeIfNoChange) {
        return;
      }
      callback(newStatusValue, isValueChanged);
    });
  }

  _initAvailableTypes() {
    this._availableTypes = {
      number: 'number',
      bigint: 'bigint',
      string: 'string',
      boolean: 'boolean',
    };

    Object.defineProperty(this._availableTypes, 'getSeparatedString', {
      value(separator) {
        return Object.values(this).join(separator);
      },
    });
  }

  _isCorrectTypeCheck(valueType) {
    return Object.values(this._availableTypes).some(availableType => availableType === valueType);
  }
}

export default ListeningVariable;
