import { makeAutoObservable } from 'mobx';

class PreloaderStore {
  constructor() {
    this.mainPreloader = false;
    makeAutoObservable(this);
  }

  openMainPreloader = value => {
    this.mainPreloader = value;
  };
}

export default PreloaderStore;
