import { makeAutoObservable } from 'mobx';

class GlobalPropsStore {
  constructor() {
    this.globalError = null;
    makeAutoObservable(this);
  }

  setGlobalError = (code, message) => {
    this.globalError = { code, message };
  };

  removeGlobalError = () => {
    this.globalError = null;
  };
}

export default GlobalPropsStore;
