import { React, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store';
import Button from '../Form/Button/Button';
import Modal from './Modal';

function JoinLesson() {
  const [link, setLink] = useState('');
  const {
    modalWindowsStore: { isOpenJoinLesson, openJoinLessonModal },
  } = useStore();

  if (!isOpenJoinLesson) {
    return null;
  }

  const changeInput = value => {
    setLink(value);
  };

  const submitBtn = e => {
    e.preventDefault();
    const a = document.createElement('a');
    a.href = link;
    a.click();
  };

  return (
    <Modal
      title="Вставьте ссылку на урок"
      size="join-lesson"
      onClose={() => openJoinLessonModal(false)}
    >
      <p>
        Cсылку на урок вы можете найти на почте,
        <br /> в переписке с вашим педагогом, а также в вашем расписании
      </p>
      <form className="join-lesson-wrapper" onSubmit={submitBtn}>
        <input
          placeholder="Вставьте ссылку на урок"
          className="join-lesson-input"
          value={link}
          onChange={e => changeInput(e.target.value)}
        />
        <Button text="Присоединиться" classname="home__join-btn" onClick={submitBtn} onSubmit />
      </form>
    </Modal>
  );
}

export default observer(JoinLesson);
