import { useEffect } from 'react';
import { toast } from 'react-toastify';
import isPathVisitedByAllowedBrowser from '../browserDetection/isPathVisitedByAllowedBrowser';

function useDisplayForbiddenBrowserNotification(history) {
  useEffect(() => {
    let isNotificationDisplayed = false;
    const showNotification = () => {
      const notificationMessage =
        'Стабильная работа плеера видеоконференций гарантируется только на браузере Chrome. Просьба воспользоваться этим браузером';
      if (!isNotificationDisplayed) {
        toast.warning(notificationMessage, {
          position: 'top-right',
          hideProgressBar: false,
          autoClose: 10000,
          theme: 'light',
        });
        isNotificationDisplayed = true;
      }
    };
    if (!isPathVisitedByAllowedBrowser(history.location)) {
      showNotification();
    }
    return history.listen(location => {
      if (!isPathVisitedByAllowedBrowser(location)) {
        showNotification();
      }
    });
  }, []);
}

export default useDisplayForbiddenBrowserNotification;
