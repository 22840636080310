import history from '../../../utils/history/history';
import navigation from '../../../constants/navigation';

function lessonIsEnded() {
  this.queue.clearAllTaskImmediately();
  this.mediasoup.stopMediaTransfer();
  history.push(navigation.lessonEnd.pathWithSearch);
}

export default lessonIsEnded;
