export default function chatSocketEventCallback(action, data) {
  if (action === this.chatEvents.newMessageChat) {
    console.log('case newMessageChat', data);
    const { chat: chatObj } = data.payload.lesson;
    const adaptedMessage = this.messageAdapter.setMessage({ chatObj });
    this.chatStore.addMessage(adaptedMessage);
    console.log('adaptedMessage', adaptedMessage);
    if (this.chatStore.isChatOpen) {
      this.chatStore.updateCounter(0);
    }
  }
}
