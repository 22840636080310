import history from '../../../utils/history/history';
import navigation from '../../../constants/navigation';

async function lessonBreakStopped(isStudent) {
  console.log('lessonBreackStopped');
  this.isLessonOnPause = false;
  if (isStudent) {
    this.modalWindowsStore.openFinishLessonBreak(true);
  } else {
    await this.startLocalMedia();
    history.push(navigation.videoConference.pathWithSearch);
  }
}

export default lessonBreakStopped;
