import React from 'react';
import { ReactComponent as MostikLogo } from '../../assets/img/logo_type_1.svg';

function MainPreloader() {
  return (
    <div className="main-preloader">
      <div className="main-preloader__content">
        <MostikLogo className="main-preloader__logo-icon" />
      </div>
    </div>
  );
}

export default MainPreloader;
