async function getLessonInfo(lesson) {
  const {
    isBreak,
    isIndividualLesson,
    firstButtonImage,
    firstButtonName,
    secondButtonImage,
    secondButtonName,
    toiletButtonIsEnabled,
    lessonName,
    teacherName,
  } = lesson;
  this.isIndividualLesson = isIndividualLesson;
  this.isLessonOnPause = isBreak;
  this.lessonName = lessonName;
  this.teacherName = teacherName;
  const { setToiletButton, setFirstButton, setSecondButton } = this.buttonsStore;
  if (toiletButtonIsEnabled) {
    setToiletButton(true);
  }
  if (firstButtonName && firstButtonImage) {
    setFirstButton(firstButtonName, firstButtonImage);
  }
  if (secondButtonName && secondButtonImage) {
    setSecondButton(secondButtonName, secondButtonImage);
  }
  this.isLessonInformationExist = true;
}

export default getLessonInfo;
