import io from 'socket.io-client';
import history from '../../utils/history/history';
import navigation from '../../constants/navigation';
import localStorage from '../../utils/localStorage';
/* eslint-disable import/no-cycle */
import { authApi } from '../../api/apiEndpoints';
import ListeningVariable from '../../utils/ListeningVariable';
import RawEvents from './rawEvents';
import executeEventAdapter from './executeEventAdapter';

class Socket {
  constructor(
    educationStakeholder,
    lessonHash,
    modalWindowsStore,
    exitFromLesson,
    oppositeEventNames,
  ) {
    this._soccket = null;
    this.modalWindowsStore = modalWindowsStore;
    this.educationStakeholder = educationStakeholder;
    this.lessonHash = lessonHash;
    this.exitFromLesson = exitFromLesson;
    this.successSocketRequestCallbacks = new Map();
    this.failureSocketRequestCallbacks = new Map();
    this.successSocketReconnectCallbacks = new Map();
    this.socketDisconnectCallbacks = new Map();
    this.socketEventCallbacks = new Map();
    this.rawEvents = new RawEvents(
      this._executeEvent.bind(this),
      oppositeEventNames,
      executeEventAdapter,
    );
    this._action = 'request';
    this._events = {
      _name: 'event',
      connect: 'connect',
      disconnect: 'disconnect',
      connect_error: 'connect_error',
    };
    this._wasConnected = false;
    this.isReconnecting = new ListeningVariable(false);
    this._reconnectionAttempts = {
      totalAmount: 10,
      currentAmount: 0,
    };
    this.request = this.request.bind(this);
  }

  connect(isTeacherUrl) {
    this._socket = io(process.env.REACT_APP_MEDIASERVER_API_URL, {
      transports: ['websocket'],
      reconnectionAttempts: this._reconnectionAttempts.totalAmount,
      auth: {
        token: localStorage.accessToken ?? 'token',
        lessonHash: this.lessonHash,
        isTeacherUrl,
      },
    });

    this._onDisconnect();
    this._onEvent();
    this._onReconnectAttempt();
    this._onReconnectSuccess();
    this._onReconnectError();
    this._onReconnectFailed();

    return new Promise((resolve, reject) => {
      this._onConnect(data => {
        resolve(data);
      });
      this._onConnectError(error => {
        reject(error);
      });
    });
  }

  disconnect() {
    if (this._socket.connected) {
      this._socket.disconnect();
    }
  }

  request(action, payload) {
    console.log('Socket request', JSON.stringify(action), payload);
    return new Promise((resolve, reject) => {
      const isReconnectingCallbackName = `${action}_promiseReject_${Date.now()}`;
      this.isReconnecting.addListener(
        isReconnectingCallbackName,
        async () => {
          const requestFailedError = new Error('Request failed');
          await this._requestFailureActions(action, requestFailedError);
          this.isReconnecting.removeListener(isReconnectingCallbackName);
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ action, requestFailedError });
        },
        { executeIfNoChange: false },
      );
      this._socket.emit(
        this._action,
        {
          action,
          payload: { ...payload, lessonHash: this.lessonHash, lesson_hash: this.lessonHash },
        },
        async data => {
          this.isReconnecting.removeListener(isReconnectingCallbackName);
          if (data.data) {
            console.log('Socket response FAIL', JSON.stringify(action), data);
            await this._requestFailureActions(action, data);
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ action, data });
          } else {
            console.log('Socket response SUCCESS', JSON.stringify(action), data);
            await this._requestSuccessActions(action, data);
            resolve({ action, data });
          }
        },
      );
    });
  }

  setSuccessSocketRequestCallback(key, callback) {
    if (typeof callback === 'function') {
      this.successSocketRequestCallbacks.set(key, callback);
    } else {
      throw new Error(`Callback is not a function. ${callback}`);
    }
  }

  setFailureSocketRequestCallback(key, callback) {
    if (typeof callback === 'function') {
      this.failureSocketRequestCallbacks.set(key, callback);
    } else {
      throw new Error(`Callback is not a function. ${callback}`);
    }
  }

  setSocketEventCallback(key, callback) {
    if (typeof callback === 'function') {
      this.socketEventCallbacks.set(key, callback);
    } else {
      throw new Error(`Callback is not a function. ${callback}`);
    }
  }

  setSocketDisconnectCallback(key, callback) {
    if (typeof callback === 'function') {
      this.socketDisconnectCallbacks.set(key, callback);
    } else {
      throw new Error(`Callback is not a function. ${callback}`);
    }
  }

  setSuccessSocketReconnectCallback(key, callback) {
    if (typeof callback === 'function') {
      this.successSocketReconnectCallbacks.set(key, callback);
    } else {
      throw new Error(`Callback is not a function. ${callback}`);
    }
  }

  _onConnect(callback) {
    this._socket.on(this._events.connect, data => {
      localStorage.delete(localStorage.fields.lessonAuthorizationData);
      if (callback) {
        callback(data);
      }
      this._wasConnected = true;
      this._reconnectionAttempts.currentAmount = 0;
      this.isReconnecting.value = false;
      return data;
    });
  }

  _onDisconnect() {
    this._socket.on(this._events.disconnect, async reason => {
      console.log('Socket disconnect', reason);
      for (const entry of this.socketDisconnectCallbacks) {
        const [, callback] = entry;
        await callback(reason);
      }
    });
  }

  _onConnectError(callback) {
    this._socket.on(this._events.connect_error, async error => {
      console.log('Socket connect error', { error });
      let isExecuteCallback = true;
      const { data: errorData } = error;
      const errorCode = errorData?.code || 500;
      const errorMessage = errorData?.message || '';

      const redirectToErrorCodePage = () => {
        const { pathname, search } = history.location;
        history.replace(
          { pathname, search },
          { errorStatusCode: errorCode, errorMessage: errorCode === 403 ? errorMessage : '' },
        );
        if (errorCode !== 500) {
          this.exitFromLesson(true);
          const { openReloadSocketConnection } = this.modalWindowsStore;
          openReloadSocketConnection(false);
        }
      };

      switch (errorCode) {
        case 401: {
          try {
            await authApi.refreshToken();
            this._socket.auth.token = localStorage.accessToken;
            this._socket.connect();
            isExecuteCallback = false;
          } catch (e) {
            localStorage.lessonAuthorizationData = {
              lessonHash: this.lessonHash,
              educationStakeholder: this.educationStakeholder,
            };
            this._socket.disconnect();
          }
          break;
        }
        case 400:
          history.push(navigation.home.path);
          break;
        case 500: {
          const { currentAmount, totalAmount } = this._reconnectionAttempts;
          if (currentAmount >= totalAmount) {
            redirectToErrorCodePage();
          } else {
            isExecuteCallback = false;
          }
          break;
        }
        default: {
          redirectToErrorCodePage();
        }
      }
      if (callback && isExecuteCallback) {
        callback(error.data ?? { code: 500, message: 'Не удалось подключиться к серверу' });
      }
      return error;
    });
  }

  _onEvent() {
    this._socket.on(this._events._name, async data => {
      console.log('Socket event', JSON.stringify(data.action), data);
      await this._executeEvent(data);
    });
  }

  _onReconnectAttempt() {
    this._socket.io.on('reconnect_attempt', attempt => {
      this.isReconnecting.value = true;
      if (attempt === 1 && this._wasConnected) {
        const { openReloadSocketConnection } = this.modalWindowsStore;
        openReloadSocketConnection(true);
      }
      this._reconnectionAttempts.currentAmount = attempt;
    });
  }

  _onReconnectSuccess() {
    this._socket.io.on('reconnect', async attempt => {
      console.log('Socket success reconnect', attempt);
      this.isReconnecting.value = false;
      const { openReloadSocketConnection } = this.modalWindowsStore;
      const minimumModalWindowOpeningTime = 2000;
      const startTime = performance.now();
      for (const entry of this.successSocketReconnectCallbacks) {
        const [, callback] = entry;
        try {
          await callback(attempt);
        } catch (e) {
          console.error(e);
        }
      }
      const endTime = performance.now();
      const executionTime = endTime - startTime;
      if (executionTime < minimumModalWindowOpeningTime) {
        const residualDelay =
          attempt === 1 ? Math.round(minimumModalWindowOpeningTime - executionTime) : 0;
        const timeoutID = setTimeout(() => {
          openReloadSocketConnection(false);
          clearTimeout(timeoutID);
        }, residualDelay);
      } else {
        openReloadSocketConnection(false);
      }
      this._reconnectionAttempts.currentAmount = 0;
    });
  }

  _onReconnectError() {
    this._socket.io.on('reconnect_error', () => {
      console.log('Socket reconnect error');
    });
  }

  _onReconnectFailed() {
    this._socket.io.on('reconnect_failed', () => {
      console.log('Socket failed reconnect');
      this.isReconnecting.value = false;
    });
  }

  async _executeEvent(data) {
    const { action } = data;
    for (const entry of this.socketEventCallbacks) {
      const [, callback] = entry;
      await callback(action, data);
    }
  }

  async _requestSuccessActions(action, data) {
    for (const entry of this.successSocketRequestCallbacks) {
      const [, callback] = entry;
      await callback(action, data);
    }
  }

  async _requestFailureActions(action, data) {
    const { name } = data ?? {};
    const { openReloadApp } = this.modalWindowsStore;
    if (name === 'BadRequestError' || name === 'ValidationError' || name === 'ModelNotFoundError') {
      openReloadApp(true);
    }
    for (const entry of this.failureSocketRequestCallbacks) {
      const [, callback] = entry;
      await callback(action, data);
    }
  }
}

export default Socket;
