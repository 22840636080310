import UserStore from './UserStore';
import ModalWindowsStore from './modalWindowsStore';
import MediaStore from './MediaStore';
import ChatStore from './ChatStore';
import ButtonsStore from './ButtonsStore';
import StudentOptionsStore from './StudentOptionsStore';
import ReactionStore from './ReactionStore';
import PreloaderStore from './PreloaderStore';
import GlobalPropsStore from './GlobalPropsStore';

class RootStore {
  constructor(context) {
    this.userStore = new UserStore(context);
    this.modalWindowsStore = new ModalWindowsStore(context);
    this.mediaStore = new MediaStore();
    this.buttonsStore = new ButtonsStore();
    this.chatStore = new ChatStore();
    this.studentOprionsStore = new StudentOptionsStore(context);
    this.reactionStore = new ReactionStore();
    this.preloaderStore = new PreloaderStore();
    this.globalPropsStore = new GlobalPropsStore();
  }

  getStore = storeName => this[storeName];
}

export default RootStore;
