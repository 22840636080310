import attention1 from './assets/attention1.mp3';
import attention2 from './assets/attention2.mp3';
import attention3 from './assets/attention3.mp3';
import attention4 from './assets/attention4.mp3';
import yes from './assets/yes.mp3';
import no from './assets/no.mp3';
import ring from './assets/ring.mp3';
import stop from './assets/stop.mp3';

const audios = {
  attention1: {
    type: 'attention1',
    audio: new Audio(attention1),
  },
  attention2: {
    type: 'attention2',
    audio: new Audio(attention2),
  },
  attention3: {
    type: 'attention3',
    audio: new Audio(attention3),
  },
  attention4: {
    type: 'attention4',
    audio: new Audio(attention4),
  },
  yes: {
    type: 'yes',
    audio: new Audio(yes),
  },
  no: {
    type: 'no',
    audio: new Audio(no),
  },
  ring: {
    type: 'ring',
    audio: new Audio(ring),
  },
  stop: {
    type: 'stop',
    audio: new Audio(stop),
  },
};

function initAudios() {
  const { body: bodyElement } = document;
  function unlockAudios() {
    Object.values(audios).forEach(({ audio }) => {
      audio.muted = true;
      audio.play();
      audio.pause();
      audio.currentTime = 0;
      audio.muted = false;
    });
    document.body.removeEventListener('click', unlockAudios);
    document.body.removeEventListener('touchstart', unlockAudios);
  }
  bodyElement.addEventListener('click', unlockAudios);
  bodyElement.addEventListener('touchstart', unlockAudios);
}

export default audios;
export { initAudios };
