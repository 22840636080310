/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import vkLogo from '../../assets/img/vk.png';
import okLogo from '../../assets/img/ok.png';
import tgLogo, { ReactComponent as Telegram } from '../../assets/img/telegram.svg';
import { ReactComponent as Viber } from '../../assets/img/viber.svg';
import { ReactComponent as WhatsApp } from '../../assets/img/whatsapp.svg';

const links = [
  {
    href: 'https://t.me/mostikschool',
    child: <Telegram />,
    key: 'telegram',
  },
  {
    href: 'https://wa.me/79951562671',
    child: <WhatsApp />,
    key: 'whatsapp',
  },
  {
    href: 'viber://add?number=79951562671',
    child: <Viber />,
    key: 'viber',
  },
];

export default function Footer() {
  const handleClick = link => {
    window.open(link, '_blank');
  };

  return (
    <footer className="footer-container">
      <div className="footer">
        <div className="footer__first-line">
          <div className="footer__contacts">
            <a className="footer__contacts__tel" href="tel:+7(995)156-26-71">
              +7 (995) 156 - 26 - 71
            </a>
            <div className="footer__contacts__schedule">
              <div className="footer__contacts__schedule__online" />
              <div className="footer__contacts__schedule__container">
                <div className="footer__contacts__schedule__text">
                  На связи с 10:00 по 20:00 по Мск
                </div>
              </div>
            </div>
            <div className="footer__contacts__messengers">
              {!!links.length &&
                links.map(link => (
                  <a
                    className="footer__contacts__messengers__button"
                    href={link.href}
                    rel="noreferrer"
                    target="_blank"
                    key={link.key}
                  >
                    {link.child}
                  </a>
                ))}
            </div>
          </div>
          <div className="footer__faq">
            <a href="https://www.mostikschool.ru/" className="footer__faq__text">
              Как можно учиться в Мостике?
            </a>
            <a href="https://www.mostikschool.ru/about" className="footer__faq__text">
              Все о Мостике
            </a>
          </div>
          <div className="footer__social">
            <div className="footer__social__links">
              <button
                type="button"
                className="footer__social__link"
                onClick={() => handleClick('https://t.me/mostik_school')}
              >
                <img src={tgLogo} alt="tg logo" className="footer__social__logo" />
              </button>
              <button
                type="button"
                className="footer__social__link"
                onClick={() => handleClick('https://vk.com/mostikschool')}
              >
                <img src={vkLogo} alt="vk logo" className="footer__social__logo" />
              </button>
              <button
                type="button"
                className="footer__social__link"
                onClick={() => handleClick('https://ok.ru/group/58522879328256')}
              >
                <img src={okLogo} alt="ok logo" className="footer__social__logo" />
              </button>
            </div>
          </div>
        </div>
        <div className="footer__divider" />
        <div className="footer__info">
          <div className="footer__info__address">
            Москва, Большая Марфинская улица, 1к3 Округ СВАО, Район Марфино
          </div>
          <div className="footer__info__docs">
            <a
              className="footer__info__privacy"
              href="https://www.mostikschool.ru/license"
              rel="noreferrer"
              target="_blank"
            >
              Лицензия
            </a>
            <a
              className="footer__info__privacy"
              href="https://www.mostikschool.ru/officialinfo"
              rel="noreferrer"
              target="_blank"
            >
              Сведения об образовательной организации
            </a>
            <a
              className="footer__info__privacy"
              href="https://mostikschool.ru/offercontract"
              rel="noreferrer"
              target="_blank"
            >
              Договор-оферта
            </a>
            <a
              className="footer__info__privacy"
              href="https://mostikschool.ru/policyregardingtheprocessingofpersonaldata"
              rel="noreferrer"
              target="_blank"
            >
              Правила пользования
            </a>
          </div>
          <div className="footer__info__copyright">
            <div className="footer__info__copyright__text">
              © Мостик, {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
