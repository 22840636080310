import React from 'react';
import { observer } from 'mobx-react-lite';
import MainPreloader from './MainPreloader';
import { useStore } from '../../store';

function MainPreloaderWithUseStore() {
  const {
    preloaderStore: { mainPreloader },
  } = useStore();

  if (!mainPreloader) {
    return null;
  }
  return <MainPreloader />;
}

export default observer(MainPreloaderWithUseStore);
