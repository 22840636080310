// const eventArgument = {
//   eventName: string,
//   payload: object,
//   pairID: string,
//   peerID: string,
//   ? deleteSamePeerID: bool,
// };

// const events = {
//   peerID: [{
//     eventName: string,
//     payload: object,
//     pairID: string,
//     peerID: string,
//     ? deleteSamePeerID: bool,
//   }],
// };

class RawEvents {
  constructor(executor, oppositeEventNames, executorAdapter) {
    this.events = {};
    this.executor = executor;
    this.oppositeEventNames = null;
    this.executorAdapter = executorAdapter || null;
    this._initOppositeEventNames(oppositeEventNames);
  }

  push(event, pushToStart = false) {
    this.events = this._push(this.events, event, pushToStart);
  }

  pop(peerID, eventName, pairID = null) {
    this.events = this._pop(this.events, peerID, eventName, pairID);
  }

  async execute() {
    this._removeRedundantEvents();
    const eventsArray = [...this._eventsToArray()];
    for (const event of eventsArray) {
      const { eventName, peerID, pairID } = event;
      let data = event;
      if (this.executorAdapter) {
        data = this.executorAdapter(event);
      }
      await this.executor(data);
      this.pop(peerID, eventName, pairID);
    }
  }

  setExecutorAdapter(adapter) {
    this.executorAdapter = adapter;
  }

  removeExecutorAdapter() {
    this.executorAdapter = null;
  }

  _eventsToArray() {
    return Object.values(this.events).flat();
  }

  _removeRedundantEvents() {
    let resultEvents = {};
    Object.entries(this.events).forEach(([peerID, events]) => {
      events.forEach(event => {
        const { eventName, pairID } = event;
        const isOppositeEvent = this.oppositeEventNames[eventName];
        if (isOppositeEvent) {
          const isCloseEvent = !this.oppositeEventNames[eventName].isOpenEvent;
          if (isCloseEvent) {
            const isPeerIDExist = !!resultEvents[peerID];
            if (isPeerIDExist) {
              const openEventName = this.oppositeEventNames[eventName].eventName;
              const openEvent = resultEvents[peerID].find(eventItem =>
                this._compareEvent(eventItem, openEventName, pairID),
              );
              if (openEvent) {
                resultEvents = this._pop(resultEvents, peerID, openEventName, pairID);
                if (event.deleteSamePeerID) {
                  resultEvents = this._removeSamePeerID(resultEvents, peerID);
                }
              } else {
                resultEvents = this._push(resultEvents, event);
              }
            }
          } else {
            resultEvents = this._push(resultEvents, event);
          }
        } else {
          resultEvents = this._push(resultEvents, event);
        }
      });
    });
    this.events = resultEvents;
  }

  _push = (events, event, isPushToStart = false) => {
    const { peerID } = event;
    if (!events[peerID]) {
      events[peerID] = [];
    }
    if (isPushToStart) {
      events[peerID].unshift(event);
    } else {
      events[peerID].push(event);
    }
    return events;
  };

  _pop(events, peerID, eventName, pairID = null) {
    const resultEvents = { ...events };
    resultEvents[peerID] = [...resultEvents[peerID]];
    const eventIndex = resultEvents[peerID].findIndex(eventItem =>
      this._compareEvent(eventItem, eventName, pairID),
    );
    if (eventIndex !== -1) {
      resultEvents[peerID].splice(eventIndex, 1);
    }
    return resultEvents;
  }

  _removeSamePeerID = (events, peerID) => {
    const resultEvents = { ...events };
    delete resultEvents[peerID];
    return resultEvents;
  };

  _compareEvent = (eventItem, eventName, pairID) =>
    eventItem.eventName === eventName && (pairID ? eventItem.pairID === pairID : true);

  _initOppositeEventNames(oppositeEventNames = {}) {
    const result = oppositeEventNames;
    Object.entries(oppositeEventNames).forEach(([key, value]) => {
      result[key] = { isOpenEvent: true, eventName: value };
      result[value] = { isOpenEvent: false, eventName: key };
    });
    this.oppositeEventNames = result;
  }
}

export default RawEvents;
