import apiURLs from '../../constants/apiURLs';

class TrainingEndpoint {
  constructor(axios, setBearer, removeBearer) {
    this.axios = axios;
    this.setBearer = setBearer;
    this.removeBearer = removeBearer;
  }

  programs = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.training,
      });
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  };

  materials = async (id, page) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.resource(id),
        params: {
          page,
          per_page: '20',
        },
      });
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  };

  downloadFile = async (id, hash) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.downloadFile(id),
        params: { hash },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}

export default TrainingEndpoint;
