import AuthEndpoint from './AuthEndpoint';
import TrainingEndpoint from './TrainingEndpoint';
import ChatEndpoint from './ChatEndpoint';
import ProfileEndpoint from './ProfileEndpoint';
import ProductsEndpoint from './ProductsEndpoint';
import SubjectsEndpoint from './SubjectsEndpoint';
import MaterialsEndpoints from './MaterialsEndpoints';
import MyProgressEndpoints from './MyProgress';
import StudentsEndpoint from './StudentsEndpoint';
import EducationEventsEndpoint from './EducationEventsEndpoint';

export default [
  { name: 'auth', Endpoint: AuthEndpoint },
  { name: 'training', Endpoint: TrainingEndpoint },
  { name: 'chat', Endpoint: ChatEndpoint },
  { name: 'profile', Endpoint: ProfileEndpoint },
  { name: 'products', Endpoint: ProductsEndpoint },
  { name: 'subjects', Endpoint: SubjectsEndpoint },
  { name: 'materials', Endpoint: MaterialsEndpoints },
  { name: 'myProgress', Endpoint: MyProgressEndpoints },
  { name: 'students', Endpoint: StudentsEndpoint },
  { name: 'educationEvents', Endpoint: EducationEventsEndpoint },
];
