/* eslint-disable no-return-await */
import axios from 'axios';
import { toast } from 'react-toastify';

import endpoints from './endpoints';
import navigation from '../constants/navigation';
import randomInteger from '../utils/randomInteger';
import history from '../utils/history/history';
import apiURLs from '../constants/apiURLs';
import localStorage from '../utils/localStorage';
/* eslint-disable import/no-cycle */
import { lesson } from '../Lesson/Lesson';

let refreshing = false;

const repeatInterval = () =>
  new Promise(resolve => {
    const interval = setInterval(() => {
      if (!refreshing) {
        resolve();
        clearInterval(interval);
      }
    }, 100);
  });

class Api {
  constructor() {
    this.token = localStorage.accessToken;
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
      // baseURL: 'https://mostik-dev-backend.truemachine.space/api/v1',
      // baseURL: 'http://localhost:8042',
    });
    this.isOriginalRequestResent = false;
    this.endpoints = Object.fromEntries(
      endpoints.map(({ name, Endpoint }) => [
        name,
        new Endpoint(this.axios, this.setBearer, this.removeBearer),
      ]),
    );
    this.init();
  }

  setBearer = token => {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  removeBearer = () => {
    delete this.axios.defaults.headers.common.Authorization;
  };

  repeatRequest = originalRequest => {
    originalRequest.headers.Authorization = `Bearer ${localStorage.accessToken}`;
    return new Promise((resolve, reject) => {
      this.axios(originalRequest)
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  refresh(originalRequest) {
    return new Promise((resolve, reject) => {
      this.endpoints.auth
        .refreshToken()
        .then(() => {
          refreshing = false;
          this.repeatRequest(originalRequest)
            .then(res => resolve(res))
            .catch(err => reject(err));
        })
        .catch(() => {
          refreshing = false;
        });
    });
  }

  sleepRequest = (seconds, originalRequest) =>
    new Promise(resolve => {
      setTimeout(() => resolve(this.axios(originalRequest)), seconds * 1000);
    });

  init = async () => {
    this.setBearer(this.token);
    this.axios.interceptors.response.use(
      response => Promise.resolve(response),
      async err => {
        const timer = randomInteger(3, 30);
        const httpStatus = err?.response?.status ?? 500;

        // следить за запросами, чтобы не появилась др вложенность, напр: err?.response?.data?.errors?.status_code (часто при интеграциях возникает)
        switch (httpStatus) {
          case 400:
            toast.warning('Bad Request.');
            break;
          case 401: {
            const originalRequest = err.config;
            const isRefreshTokenRequest = err.response.config.url === apiURLs.refresh;
            const isLoginRequest = err.response.config.url === apiURLs.login;
            if (!isRefreshTokenRequest) {
              if (!isLoginRequest) {
                if (!refreshing) {
                  refreshing = true;
                  return this.refresh(originalRequest);
                  // eslint-disable-next-line no-else-return
                } else {
                  await repeatInterval();
                  return await this.repeatRequest(originalRequest);
                }
              }
            } else {
              if (isRefreshTokenRequest) {
                if (lesson) {
                  lesson.exitFromLesson(true);
                }
              }
              localStorage.deleteAll();
              this.removeBearer();
              history.push(navigation.authorization.path);
            }
            break;
          }
          case 403:
            localStorage.deleteAll();
            this.removeBearer();
            history.push(navigation.authorization.path);
            toast.warning(
              'Что-то пошло не так. Возможно, ваш аккаунт заблокирован или удален. Свяжитесь с администрацией школы, мы постараемся помочь.',
            );
            break;
          case 404:
            if (history.location.pathname === '/authorization') {
              toast.warning(
                'Что-то пошло не так. Возможно, ваш аккаунт заблокирован или удален. Свяжитесь с администрацией школы, мы постараемся помочь.',
              );
            } else if (history.location.pathname === '/notification') {
              toast.warning(err.response.data.error.message);
            } else {
              toast.warning('Not Found.');
            }
            break;
          case 405:
            toast.warning('Method Not Allowed.');
            break;
          case 406:
            toast.warning('Not Acceptable.');
            break;
          case 407:
            toast.warning('Proxy Authentication Required.');
            break;
          case 423:
            this.removeBearer();
            localStorage.deleteAll();
            toast.error('Method locked.'); // выводим error
            break;
          case 429:
          case 503:
          case 504: {
            const originalRequest = err.config;
            this.sleepRequest(timer, originalRequest);
            break;
          }
          case 500:
            toast.error('Internal server error.');
            break;
          case 501:
            toast.error('Not Implemented.');
            break;
          case 502:
            toast.error('Bad Gateway.');
            break;
          case 505:
            toast.error('HTTP Version Not Supported.');
            break;
          // no default
        }
        return Promise.reject(err);
      },
    );
  };
}

const api = new Api();

export default api;
