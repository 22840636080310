import { toast } from 'react-toastify';
import checkIsPeerIdRemotePeerId from '../../../utils/checkIsPeerIdRemotePeerId';

function userLeaveFromLesson(action, payload) {
  const { id: peerId, fullName, userId } = payload.peer;
  const { isTeacher } = this.roleInLesson;
  const isRemotePeerId = checkIsPeerIdRemotePeerId(this.peerInfo, peerId);

  if (!this.isReadyToHandleEvents) {
    const event = {
      eventName: action,
      eventData: { action, payload },
      peerID: peerId,
      deleteSamePeerID: true,
    };
    this.socket.rawEvents.push(event);
    return;
  }

  if (isRemotePeerId && this.peerInfo.userId !== userId) {
    const isTeacherPeerId = this.teacherPeerId === peerId;
    this.queue.pushTask(peerId, () => {
      this.mediasoup.removeConsumer(peerId, isTeacherPeerId);
      if (isTeacherPeerId) {
        this.teacherPeerId = '';
      }
      this.queue.resumeTaskExecuting(peerId);
    });
    this.queue.clearTask(peerId);
    if (isTeacher) {
      toast.info(`${fullName === '  ' ? 'Неизвестный пользователь' : fullName} покинул(а) урок`);
    }
  }
}

export default userLeaveFromLesson;
