import history from '../../../utils/history/history';

function forceDisconnect(payload) {
  console.log('forceDisconnect event', payload);
  const { code, message } = payload;
  this.exitFromLesson();
  const { pathname, search } = history.location;
  history.replace({ pathname, search }, { errorStatusCode: code, errorMessage: message });
}

export default forceDisconnect;
