import { mediaTypes } from '../../constants/mediaTypes';

class MediasoupCanProduceError extends Error {
  constructor(mediaType) {
    super('');
    this.name = this.constructor.name;
    this._setMediaType(mediaType);
    this._setMessage();
  }

  _setMediaType(mediaType) {
    if (
      mediaType === mediaTypes.audioType ||
      mediaType === mediaTypes.videoType ||
      mediaType === mediaTypes.screenType
    ) {
      this.mediaType = mediaType;
    } else {
      this.mediaType = 'Unknown';
    }
  }

  _setMessage() {
    let message = '';
    switch (this.mediaType) {
      case mediaTypes.audioType:
        message = 'Ваш микрофон не поддерживается на этом уроке';
        break;
      case mediaTypes.videoType:
        message = 'Ваша камера не поддерживается на этом уроке';
        break;
      case mediaTypes.screenType:
        message = 'Невозможно запустить трансляцию экрана на этом уроке';
        break;
      default:
        message = 'Ошибка отправки вашего медиа';
    }

    this.message = message;
  }
}

export default MediasoupCanProduceError;
