function userGotNewMessage(payload) {
  console.log('event newMessageChat', payload);
  const unreadAmount = +localStorage.getItem('amount_unread') + 1;
  const sender_id = payload.lesson.chat.message.sender.user_id;
  const user_id = localStorage.getItem('userId');
  const { id } = payload.lesson.chat.message;
  const unread_messages = JSON.parse(localStorage.getItem('unread_messages'));
  const messsages = [...unread_messages, id];
  localStorage.setItem('unread_messages', JSON.stringify(messsages));
  if (!this.chatStore.isChatOpen) {
    this.chatStore.updateCounter(unreadAmount);
  } else if (sender_id !== user_id) {
    this.readMessageChat(id);
  }
}

export default userGotNewMessage;
