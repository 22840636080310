import apiURLs from '../../constants/apiURLs';

class ProductsEndpoint {
  constructor(axios, setBearer, removeBearer) {
    this.axios = axios;
    this.setBearer = setBearer;
    this.removeBearer = removeBearer;
  }

  getProducts = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getProducts,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getSubjects = async id => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getSubjects(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getSubjectsForSelect = async (params, page) => {
    const filterParams = { ...params };
    if (filterParams.name === '' || filterParams.name === null) {
      delete filterParams.name;
    }

    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getSubjectsForSelect,
        params: { name: filterParams.name, page },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getListOfProducts = async (id, filter, page) => {
    const formatterFilter = { ...filter };

    if (formatterFilter.name === '') {
      delete formatterFilter.name;
    }

    if (formatterFilter.subject_ids.length === 0) {
      delete formatterFilter.subject_ids;
    } else {
      const newSubjects = formatterFilter.subject_ids.map(item => item.value);
      formatterFilter.subject_ids = newSubjects;
    }

    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getListOfProducts(id),
        params: {
          filter: formatterFilter,
          per_page: '25',
          page,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  makeOrder = async data => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.makeOrder,
        data,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getAdditionalList = async (userId, materialId, filter, page) => {
    const formatterFilter = { ...filter };

    if (formatterFilter.name === '') {
      delete formatterFilter.name;
    }

    if (formatterFilter.subject_ids.length === 0) {
      delete formatterFilter.subject_ids;
    } else {
      const newSubjects = formatterFilter.subject_ids.map(item => item.value);
      formatterFilter.subject_ids = newSubjects;
    }
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getAdditionalList(userId, materialId),
        params: {
          name: formatterFilter.name,
          subject_ids: formatterFilter.subject_ids,
          per_page: '25',
          page,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getAdditionalNestedList = async (userId, materialId, folderId, filter, page) => {
    const formatterFilter = { ...filter };

    if (formatterFilter.name === '') {
      delete formatterFilter.name;
    }

    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getAdditionalNestedList(userId, materialId, folderId),
        params: {
          name: formatterFilter.name,
          per_page: '25',
          page,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}

export default ProductsEndpoint;
