import lessonIsStarted from './lessonIsStarted';
import lessonIsEnded from './lessonIsEnded';
import lessonBreakStarted from './lessonBreakStarted';
import lessonBreakStopped from './lessonBreakStopped';
import userJoinedToLesson from './userJoinedToLesson';
import userLeaveFromLesson from './userLeaveFromLesson';
import userStartedStream from './videoConference/userStartedStream';
import userStoppedStream from './videoConference/userStoppedStream';
import consumerLayersChanged from './videoConference/consumerLayersChanged';
import userDeletedMessage from './videoConference/userDeletedMessage';
import userGotNewMessage from './videoConference/userGotNewMessage';
import showThemeButtons from './themeButtons/showThemeButtons';
import hideThemeButtons from './themeButtons/hideThemeButtons';
import firstThemeButtonReaction from './themeButtons/firstThemeButtonReaction';
import secondThemeButtonReaction from './themeButtons/secondThemeButtonReaction';
import handUppedButton from './studentButtons/handUppedButton';
import toiletNeededButton from './studentButtons/toiletNeededButton';
import showYesNoButtons from './studentButtons/showYesNoButtons';
import yesButtonPressed from './studentButtons/yesButtonPressed';
import noButtonPressed from './studentButtons/noButtonPressed';
import showedAttentionNotification from './studentButtons/showedAttentionNotification';
import emojiEvent from './emojiEvent';
import stopNotification from './stopNotification';
import showedCallNotification from './showedCallNotification';
import forceDisconnect from './forceDisconnect';
import { mediaTypes } from '../../../constants/mediaTypes';

async function socketEvent(action, data) {
  const lessonIsStartedFunction = lessonIsStarted.bind(this);
  const lessonIsEndedFunction = lessonIsEnded.bind(this);
  const lessonBreakStartedFunction = lessonBreakStarted.bind(this);
  const lessonBreakStoppedFunction = lessonBreakStopped.bind(this);
  const userJoinedToLessonFunction = userJoinedToLesson.bind(this);
  const userLeaveFromLessonFunction = userLeaveFromLesson.bind(this);
  const userStartedStreamFunction = userStartedStream.bind(this);
  const userStoppedStreamFunction = userStoppedStream.bind(this);
  const consumerLayersChangedFunction = consumerLayersChanged.bind(this);
  const showThemeButtonsFunction = showThemeButtons.bind(this);
  const hideThemeButtonsFunction = hideThemeButtons.bind(this);
  const userDeletedMessageFunction = userDeletedMessage.bind(this);
  const firstThemeButtonReactionFunction = firstThemeButtonReaction.bind(this);
  const secondThemeButtonReactionFunction = secondThemeButtonReaction.bind(this);
  const handUppedButtonFunction = handUppedButton.bind(this);
  const toiletNeededButtonFunction = toiletNeededButton.bind(this);
  const showYesNoButtonsFunction = showYesNoButtons.bind(this);
  const yesButtonPressedFunction = yesButtonPressed.bind(this);
  const noButtonPressedFunction = noButtonPressed.bind(this);
  const emojiEventFunction = emojiEvent.bind(this);
  const stopNotificationFunction = stopNotification.bind(this);
  const showedAttentionNotificationFunction = showedAttentionNotification.bind(this);
  const showedCallNotificationFunction = showedCallNotification.bind(this);
  const userGotNewMessageFunction = userGotNewMessage.bind(this);
  const forceDisconnectFunction = forceDisconnect.bind(this);

  const { isStudent } = this.roleInLesson;
  const { payload } = data;
  switch (action) {
    case this.events.lessonIsStarted:
      await lessonIsStartedFunction();
      break;
    case this.events.lessonIsEnded:
      lessonIsEndedFunction();
      break;
    case this.events.lessonBreakStarted: {
      lessonBreakStartedFunction();
      break;
    }
    case this.events.lessonBreakStopped: {
      await lessonBreakStoppedFunction(isStudent);
      break;
    }
    case this.events.userJoinedToLesson: {
      userJoinedToLessonFunction(action, payload);
      break;
    }
    case this.events.userLeaveFromLesson: {
      userLeaveFromLessonFunction(action, payload);
      break;
    }
    case this.events.deletedMessageChat: {
      userDeletedMessageFunction(payload);
      break;
    }
    case this.events.gotNewMessageChat: {
      userGotNewMessageFunction(payload);
      break;
    }
    case this.videoConferenceEvents.userStartedStream: {
      userStartedStreamFunction(action, payload);
      break;
    }
    case this.videoConferenceEvents.userStoppedStream: {
      userStoppedStreamFunction(action, payload);
      break;
    }
    case this.videoConferenceEvents.userConsumerIsClosed: {
      break;
    }
    case this.videoConferenceTeacherEvent.showedThemeButtons: {
      showThemeButtonsFunction();
      break;
    }
    case this.videoConferenceTeacherEvent.hiddenThemeButtons: {
      hideThemeButtonsFunction();
      break;
    }
    case this.videoConferenceTeacherEvent.showedFirstThemeButtonReaction: {
      firstThemeButtonReactionFunction(payload);
      break;
    }
    case this.videoConferenceTeacherEvent.showedSecondThemeButtonReaction: {
      secondThemeButtonReactionFunction(payload);
      break;
    }
    case this.videoConferenceTeacherEvent.handUpped: {
      handUppedButtonFunction(payload);
      break;
    }
    case this.videoConferenceTeacherEvent.toiletNeeded: {
      toiletNeededButtonFunction(payload);
      break;
    }
    case this.videoConferenceTeacherEvent.showedYesNoButtons: {
      showYesNoButtonsFunction();
      break;
    }
    case this.videoConferenceTeacherEvent.showedYesButtonReaction: {
      yesButtonPressedFunction(payload);
      break;
    }
    case this.videoConferenceTeacherEvent.showedNoButtonReaction: {
      noButtonPressedFunction(payload);
      break;
    }
    case this.videoConferenceTeacherEvent.showedEmojiNotification: {
      emojiEventFunction(payload);
      break;
    }
    case this.videoConferenceTeacherEvent.showedStopNotification: {
      stopNotificationFunction();
      break;
    }
    case this.videoConferenceTeacherEvent.showedAttentionNotification: {
      showedAttentionNotificationFunction(payload);
      break;
    }
    case this.videoConferenceTeacherEvent.showedCallNotification: {
      showedCallNotificationFunction();
      break;
    }
    case this.videoConferenceTeacherEvent.startStreamByCameraNotification: {
      this.modalWindowsStore.openChangeMediaState(true, mediaTypes.videoType, true);
      break;
    }
    case this.videoConferenceTeacherEvent.stopStreamByCameraNotification: {
      this.pauseLocalVideo();
      break;
    }
    case this.videoConferenceTeacherEvent.startStreamByMicrophoneNotification: {
      this.modalWindowsStore.openChangeMediaState(true, mediaTypes.audioType, true);
      break;
    }
    case this.videoConferenceTeacherEvent.stopStreamByMicrophoneNotification: {
      this.pauseLocalAudio();
      break;
    }
    case this.videoConferenceEvents.consumerLayersChanged: {
      consumerLayersChangedFunction(payload);
      break;
    }
    case this.events.disconnect: {
      forceDisconnectFunction(payload);
    }
    // no default
  }
}

export default socketEvent;
