export default (params, searchParams) => {
  if (typeof params !== 'string' && !Array.isArray(params)) {
    throw new Error(`Param must be one of type (array || string), but get ${params}`);
  }
  function getParam(param) {
    if (searchParams) {
      return new URLSearchParams(searchParams).get(param);
    }
    return new URL(window.location.href).searchParams.get(param);
  }

  let result;

  if (typeof params === 'string') {
    result = getParam(params);
  } else if (Array.isArray(params)) {
    result = [];
    params.forEach(param => {
      result.push(getParam(param));
    });
  }
  return result;
};
