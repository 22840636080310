import { toast } from 'react-toastify';
import reactions from '../../../constants/reactions';

function showedCallNotification() {
  const { openReaction } = this.modalWindowsStore;
  const { ring } = reactions;
  const { isStudent } = this.roleInLesson;

  if (isStudent) {
    openReaction(true, ring.name);
  } else {
    toast.info('Звенит звонок');
  }
}

export default showedCallNotification;
