import { makeAutoObservable } from 'mobx';

class StudentOptionsStore {
  constructor() {
    this.isOptionsOpen = false;
    makeAutoObservable(this);
  }

  openOptionsMenu = () => {
    this.isOptionsOpen = !this.isOptionsOpen;
  };
}
export default StudentOptionsStore;
