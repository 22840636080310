import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-mutable-exports
let queue = null;

class Queue {
  constructor() {
    this.tasks = new Map();
    this._intervalDelay = 1000;
    queue = this;
  }

  pushTask(peerId, callback) {
    this._pushTask(peerId, callback, false);
  }

  unshiftTask(peerId, callback) {
    this._pushTask(peerId, callback, true);
  }

  clearTask(peerId) {
    this.pushTask(peerId, () => {
      this.clearTaskImmediately(peerId);
    });
  }

  clearAllTaskImmediately() {
    Array.from(this.tasks).forEach(([peerId]) => {
      this.clearTaskImmediately(peerId);
    });
  }

  clearTaskImmediately(peerId) {
    const isTaskExist = this.tasks.has(peerId);
    if (isTaskExist) {
      const { intervalId } = this.tasks.get(peerId);
      clearInterval(intervalId);
      this.tasks.delete(peerId);
    }
  }

  resumeTaskExecuting(peerId) {
    const isPeerIdExist = this.tasks.has(peerId);
    if (isPeerIdExist) {
      const task = this.tasks.get(peerId);
      const { callbacks } = task;
      if (callbacks.length) {
        this._executeCallback(peerId);
      } else {
        const intervalId = this._createTaskInterval(peerId);
        this.tasks.set(peerId, { callbacks, intervalId });
      }
    }
  }

  startTask(peerId) {
    const task = this.tasks.get(peerId);
    if (task) {
      const intervalId = this._createTaskInterval(peerId);
      this.tasks.set(peerId, { ...task, intervalId });
    }
  }

  _createTask(peerId, callback, isPushToStart) {
    const { callbacks, intervalId } = this.tasks.get(peerId);
    if (isPushToStart) {
      callbacks.unshift(callback);
    } else {
      callbacks.push(callback);
    }
    return { callbacks, intervalId };
  }

  _pushTask(peerId, callback, isPushToStart) {
    const isTaskExist = this.tasks.has(peerId);
    let task;
    if (isTaskExist) {
      task = this._createTask(peerId, callback, isPushToStart);
    } else {
      task = this._createNewPeerTask(peerId, callback, isPushToStart);
    }
    this.tasks.set(peerId, task);
  }

  // eslint-disable-next-line class-methods-use-this
  _createNewPeerTask(peerId, callback) {
    const callbacks = [callback];
    return { callbacks };
  }

  _createTaskInterval(peerId) {
    return setInterval(() => {
      this._executeCallback(peerId);
    }, this._intervalDelay);
  }

  _executeCallback(peerId) {
    const task = this.tasks.get(peerId);
    if (task) {
      const { callbacks } = task;
      if (callbacks.length) {
        const callBack = callbacks[0];
        callbacks.splice(0, 1);
        this.tasks.set(peerId, { ...task, callbacks });
        this._clearPeerInterval(peerId);
        try {
          callBack();
        } catch (e) {
          console.error(e);
          Sentry.captureException(e);
        }
      }
    }
  }

  _clearPeerInterval(peerId) {
    const { intervalId, callbacks } = this.tasks.get(peerId);
    clearInterval(intervalId);
    this.tasks.set(peerId, { callbacks });
  }
}

export { queue };
export default Queue;
