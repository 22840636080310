function handUppedButton(payload) {
  const { isTeacher } = this.roleInLesson;
  const { setReaction } = this.reactionStore;
  if (isTeacher) {
    setReaction({
      type: 'handUp',
      text: 'Хочет сказать',
      name: payload.lesson.student.studentName,
    });
  }
}

export default handUppedButton;
