import checkIsPeerIdRemotePeerId from '../../../../utils/checkIsPeerIdRemotePeerId';

function consumerLayersChanged(payload) {
  const { consumer } = payload.room;
  const { consumerId, spatialLayer, temporalLayer } = consumer;

  const remoteMedias = this.mediasoup.getAllRemoteMedias();
  let peerId;

  for (const remoteMedia of remoteMedias) {
    for (const info of Object.values(remoteMedia.producers)) {
      if (info.consumerId === consumerId) {
        peerId = remoteMedia.peerId;
        break;
      }
    }
    if (peerId) {
      break;
    }
  }

  const isRemotePeerId = checkIsPeerIdRemotePeerId(this.peerInfo, peerId);
  if (isRemotePeerId) {
    const isTeacher = this.teacherPeerId === peerId;
    this.queue.pushTask(peerId, () => {
      this.mediasoup.setCurrentScalabilityLayer(
        peerId,
        consumerId,
        { spatialLayer, temporalLayer },
        isTeacher,
      );
      this.queue.resumeTaskExecuting(peerId);
    });
  }
}

export default consumerLayersChanged;
