import { makeAutoObservable, toJS } from 'mobx';
import { mediaTypes } from '../constants/mediaTypes';

// Структура Media;
// localMedia = {
//   isVideo: bool,
//   isAudio: bool,
//   isScreenShare: bool,
//   [mediaType]: {
//     type: mediaType,
//     track: MediaStreamTrack,
//     producerId: string,
//     producer: Producer,
//   },
// };

// const remoteMedia = {
//   [peerId]: {
//     name: string,
//     tracksAmount: number,
//     blockedStreams: {
//        streamsBlocking: {
//            [mediaType]: boolean,
//        },
//        currentBlockedStreams: {
//            isAudio: false,
//            isVideo: false,
//            isScreenShare: false,
//            [mediaType]: {
//              consumerId: string,
//              producerId: string,
//            },
//        },
//     },
//     producers: {
//       [producerId]: {
//         consumerId: string,
//         type: mediaType,
//       },
//     },
//     media: {
//       isVideo: bool,
//       isAudio: bool,
//       isScreenShare: bool,
//       [mediaType]: {
//         type: mediaType,
//         track: MediaStreamTrack,
//         producerId: string,
//         consumer: Consumer,
//       },
//     },
//   },
// };

// teacherMedia = {
//   peerId: string,
//   name: string,
//   tracksAmount: number,
//   producers: {
//     [producerId]: {
//       consumerId: string,
//       type: mediaType,
//     },
//   },
//   media: {
//     isVideo: bool,
//     isAudio: bool,
//     isScreenShare: bool,
//     [mediaType]: {
//       type: mediaType,
//       track: MediaStreamTrack,
//       producerId: string,
//       consumer: Consumer,
//     },
//   },
// };

class MediaStore {
  constructor() {
    this.volume = '50';
    this.defaultNewMediaBlockedStreams = {
      [mediaTypes.audioType]: false,
      [mediaTypes.videoType]: false,
      [mediaTypes.screenType]: false,
    };
    this.newMediaBlockedStreams = { ...this.defaultNewMediaBlockedStreams };
    this.forcedBlockedMediaStreams = {
      [mediaTypes.audioType]: false,
      [mediaTypes.videoType]: false,
      [mediaTypes.screenType]: false,
    };
    this.remoteMedia = new Map();
    this.localMedia = { isVideo: false, isAudio: false, isScreenShare: false };
    this.selectedMediaPeerId = null;
    this.lastSelectedMediaPeerId = null;
    this.teacherMedia = null;

    makeAutoObservable(this);
  }

  setForcedBlockedMediaStreams(mediaType) {
    const { ...updatedForcedBlockedMediaStreams } = this.forcedBlockedMediaStreams;
    updatedForcedBlockedMediaStreams[mediaType] = true;
    this.forcedBlockedMediaStreams = updatedForcedBlockedMediaStreams;
  }

  removeForcedBlockedMediaStreams(mediaType) {
    const { ...updatedForcedBlockedMediaStreams } = this.forcedBlockedMediaStreams;
    updatedForcedBlockedMediaStreams[mediaType] = false;
    this.forcedBlockedMediaStreams = updatedForcedBlockedMediaStreams;
  }

  resetForcedBlockedMediaStreams() {
    this.forcedBlockedMediaStreams = {
      [mediaTypes.audioType]: false,
      [mediaTypes.videoType]: false,
      [mediaTypes.screenType]: false,
    };
  }

  setNewMediaBlockedStreams = blockedStreamsData => {
    console.log('MediaStore setNewMediaBlockedStreams', blockedStreamsData);
    this.newMediaBlockedStreams = {
      ...this.newMediaBlockedStreams,
      ...blockedStreamsData,
    };
    console.log('MediaStore after setNewMediaBlockedStreams', toJS(this.newMediaBlockedStreams));
  };

  setDefaultNewMediaBlockedStreams = blockedStreamsData => {
    this.defaultNewMediaBlockedStreams = {
      ...this.defaultNewMediaBlockedStreams,
      ...blockedStreamsData,
    };
  };

  setRemoteMedia(peerId, name) {
    console.log('mediaStore setRemoteMedia', { peerId, name });
    const remoteMedia = {
      name,
      tracksAmount: 0,
      producers: {},
      blockedStreams: {
        streamsBlocking: {
          ...this.newMediaBlockedStreams,
        },
        currentBlockedStreams: {
          isAudio: false,
          isVideo: false,
          isScreenShare: false,
        },
      },
      media: { isAudio: false, isVideo: false, isScreenShare: false },
    };
    this.remoteMedia.set(peerId, remoteMedia);
    console.log('mediaStore after setRemoteMedia', toJS(this.remoteMedia.get(peerId)));
  }

  removeRemoteMedia(peerId) {
    console.log('mediaStore removeRemoteMedia', { peerId });
    const isDeleted = this.removeRemoteMediaTracks(peerId);
    if (isDeleted) {
      if (this.selectedMediaPeerId === peerId) {
        this.unselectMedia();
      }
      this.remoteMedia.delete(peerId);
    }
  }

  removeRemoteMediaTracks(peerId) {
    console.log('mediaStore removeRemoteMediaTracks', { peerId });
    let isDeleted = false;
    if (this.remoteMedia.has(peerId)) {
      const { ...remoteMedia } = this.remoteMedia.get(peerId);
      if (remoteMedia) {
        const { tracksAmount } = remoteMedia;
        if (tracksAmount) {
          Object.values(remoteMedia.producers).forEach(({ type }) => {
            const { producerId } = remoteMedia.media[type];
            this.removeRemoteMediaTrack(peerId, producerId);
          });
        }
      }
      isDeleted = true;
    }
    return isDeleted;
  }

  setRemoteMediaTrack(peerId, producerId, mediaType, consumer, scalabilityLayers) {
    console.log('mediaStore setRemoteMediaTrack', {
      peerId,
      producerId,
      mediaType,
      consumer,
      scalabilityLayers,
    });
    if (!this.remoteMedia.has(peerId)) {
      this.setRemoteMedia(peerId, 'Неизвестное имя');
    }
    const { ...remoteMedia } = this.remoteMedia.get(peerId);
    const { id: consumerId, track } = consumer;
    remoteMedia.media = {
      ...remoteMedia.media,
      [mediaType]: { type: mediaType, track, producerId, consumer, scalabilityLayers },
    };
    if (mediaType === mediaTypes.videoType) {
      remoteMedia.media.isVideo = true;
    } else if (mediaType === mediaTypes.audioType) {
      remoteMedia.media.isAudio = true;
    } else if (mediaType === mediaTypes.screenType) {
      remoteMedia.media.isScreenShare = true;
    }
    remoteMedia.producers[producerId] = { consumerId, type: mediaType };
    remoteMedia.tracksAmount = this.countTracksAmount(remoteMedia.media);

    this.remoteMedia.set(peerId, remoteMedia);
    console.log('mediaStore after setRemoteMediaTrack', toJS(this.remoteMedia.get(peerId)));
  }

  removeRemoteMediaTrack(peerId, producerId, isSoftDelete = false) {
    console.log('mediaStore removeRemoteMediaTrack', {
      peerId,
      producerId,
      isSoftDelete,
    });
    const { ...remoteMedia } = this.remoteMedia.get(peerId);
    const { type } = remoteMedia.producers[producerId] ?? {};

    if (type) {
      const { consumer } = remoteMedia.media[type];
      if (!isSoftDelete) {
        consumer.close();
      }
      delete remoteMedia.producers[producerId];
      const { [type]: removedMedia, ...media } = remoteMedia.media;
      remoteMedia.media = media;
      if (type === mediaTypes.videoType) {
        remoteMedia.media.isVideo = false;
      } else if (type === mediaTypes.audioType) {
        remoteMedia.media.isAudio = false;
      } else if (type === mediaTypes.screenType) {
        remoteMedia.media.isScreenShare = false;
      }
      remoteMedia.tracksAmount = this.countTracksAmount(remoteMedia.media);
      this.remoteMedia.set(peerId, remoteMedia);
    }
    console.log('mediaStore after removeRemoteMediaTrack', toJS(this.remoteMedia.get(peerId)));
  }

  resetRemoteMediaBlockedStreams(peerId) {
    console.log('MediaStore resetRemoteMediaBlockedStreams', { peerId });
    const { ...remoteMedia } = this.remoteMedia.get(peerId);
    if (remoteMedia) {
      remoteMedia.blockedStreams = {
        streamsBlocking: {
          ...this.defaultNewMediaBlockedStreams,
        },
        currentBlockedStreams: {
          isAudio: false,
          isVideo: false,
          isScreenShare: false,
        },
      };
      this.remoteMedia.set(peerId, remoteMedia);
      console.log('MediaStore after resetRemoteMediaBlockedStreams', toJS(remoteMedia));
    }
  }

  setRemoteMediaCurrentScalabilityLayer(peerId, consumerId, layers) {
    console.log('MediaStore setRemoteMediaCurrentScalabilityLayer', { peerId, consumerId, layers });
    const { ...remoteMedia } = this.remoteMedia.get(peerId);
    if (remoteMedia) {
      const mediaType = Object.values(remoteMedia.producers).find(
        info => info.consumerId === consumerId,
      )?.type;
      if (mediaType && mediaType in remoteMedia.media) {
        remoteMedia.media = {
          ...remoteMedia.media,
          [mediaType]: {
            ...remoteMedia.media[mediaType],
            scalabilityLayers: {
              ...remoteMedia.media[mediaType].scalabilityLayers,
              currentSpatialLayer: layers.spatialLayer,
              currentTemporalLayer: layers.temporalLayer,
            },
          },
        };
      }
      this.remoteMedia.set(peerId, remoteMedia);
    }
    console.log(
      'MediaStore after setRemoteMediaCurrentScalabilityLayer',
      toJS(this.remoteMedia.get(peerId)),
    );
  }

  setRemoteMediaPreferredScalabilityLayer(peerId, consumerId, layers) {
    console.log('MediaStore setRemoteMediaPreferredScalabilityLayer', {
      peerId,
      consumerId,
      layers,
    });
    const { ...remoteMedia } = this.remoteMedia.get(peerId);
    if (remoteMedia) {
      const mediaType = Object.values(remoteMedia.producers).find(
        info => info.consumerId === consumerId,
      )?.type;
      if (mediaType && mediaType in remoteMedia.media) {
        remoteMedia.media = {
          ...remoteMedia.media,
          [mediaType]: {
            ...remoteMedia.media[mediaType],
            scalabilityLayers: {
              ...remoteMedia.media[mediaType].scalabilityLayers,
              preferredSpatialLayer: layers.spatialLayer,
              preferredTemporalLayer: layers.temporalLayer,
            },
          },
        };
      }
      this.remoteMedia.set(peerId, remoteMedia);
    }
    console.log(
      'MediaStore after setRemoteMediaPreferredScalabilityLayer',
      toJS(this.remoteMedia.get(peerId)),
    );
  }

  setTeacherMedia(peerId, name) {
    // eslint-disable-next-line no-console
    console.log('mediaStore setTeacherMedia', { peerId, name });
    this.teacherMedia = {
      name,
      tracksAmount: 0,
      producers: {},
      blockedStreams: {
        streamsBlocking: {
          ...this.newMediaBlockedStreams,
          [mediaTypes.videoType]: false,
        },
        currentBlockedStreams: {
          isAudio: false,
          isVideo: false,
          isScreenShare: false,
        },
      },
      media: { isAudio: false, isVideo: false, isScreenShare: false },
      peerId,
    };
  }

  removeTeacherMedia() {
    // eslint-disable-next-line no-console
    console.log('mediaStore removeTeacherMedia');
    const isDeleted = this.removeTeacherMediaTracks();
    if (isDeleted) {
      this.teacherMedia = null;
    }
  }

  removeTeacherMediaTracks() {
    // eslint-disable-next-line no-console
    console.log('mediaStore removeTeacherMediaTracks');
    let isDeleted = false;
    if (this.teacherMedia) {
      const { ...teacherMedia } = this.teacherMedia;
      if (teacherMedia) {
        const { tracksAmount } = teacherMedia;
        const { media } = teacherMedia;
        if (tracksAmount) {
          Object.values(teacherMedia.producers).forEach(({ type }) => {
            const { producerId } = media[type];
            this.removeTeacherMediaTrack(producerId);
          });
        }
      }
      isDeleted = true;
    }
    return isDeleted;
  }

  setTeacherMediaTrack(peerId, producerId, mediaType, consumer, scalabilityLayers) {
    // eslint-disable-next-line no-console
    console.log('mediaStore setTeacherMediaTrack', {
      peerId,
      producerId,
      mediaType,
      consumer,
      scalabilityLayers,
    });
    if (!this.teacherMedia) {
      this.setTeacherMedia(peerId, 'Неизвестное имя');
    }
    const { ...teacherMedia } = this.teacherMedia;
    const { id: consumerId, track } = consumer;
    teacherMedia.media = {
      ...teacherMedia.media,
      [mediaType]: { type: mediaType, track, producerId, consumer, scalabilityLayers },
    };
    teacherMedia.producers[producerId] = { consumerId, type: mediaType };
    if (mediaType === mediaTypes.videoType) {
      teacherMedia.media.isVideo = true;
    } else if (mediaType === mediaTypes.audioType) {
      teacherMedia.media.isAudio = true;
    } else if (mediaType === mediaTypes.screenType) {
      teacherMedia.media.isScreenShare = true;
    }
    teacherMedia.tracksAmount = this.countTracksAmount(teacherMedia.media);

    this.teacherMedia = teacherMedia;
    console.log('mediaStore after setTeacherMediaTrack', toJS(this.teacherMedia));
  }

  removeTeacherMediaTrack(producerId, isSoftDelete = false) {
    // eslint-disable-next-line no-console
    console.log('mediaStore removeTeacherMediaTrack', { producerId, isSoftDelete });
    const { ...teacherMedia } = this.teacherMedia;
    const { type } = teacherMedia.producers[producerId] ?? {};
    if (type) {
      const { consumer } = teacherMedia.media[type];
      if (!isSoftDelete) {
        consumer.close();
      }
      delete teacherMedia.producers[producerId];
      const { [type]: removedMedia, ...media } = teacherMedia.media;
      teacherMedia.media = media;
      if (type === mediaTypes.videoType) {
        teacherMedia.media.isVideo = false;
      } else if (type === mediaTypes.audioType) {
        teacherMedia.media.isAudio = false;
      } else if (type === mediaTypes.screenType) {
        teacherMedia.media.isScreenShare = false;
      }
      teacherMedia.tracksAmount = this.countTracksAmount(teacherMedia.media);
      this.teacherMedia = teacherMedia;
    }
    // eslint-disable-next-line no-console
    console.log('MEDIASTORE1 after removeTeacherMediaTrack', toJS(this.teacherMedia));
  }

  resetTeacherBlockedStreams() {
    console.log('MediaStore resetTeacherBlockedStreams');
    const { ...teacherMedia } = this.teacherMedia;
    if (teacherMedia) {
      teacherMedia.blockedStreams = {
        streamsBlocking: {
          ...this.defaultNewMediaBlockedStreams,
          [mediaTypes.videoType]: false,
        },
        currentBlockedStreams: {
          isAudio: false,
          isVideo: false,
          isScreenShare: false,
        },
      };
      this.teacherMedia = teacherMedia;
      console.log('MediaStore after resetTeacherBlockedStreams', toJS(this.teacherMedia));
    }
  }

  setTeacherMediaCurrentScalabilityLayer(consumerId, layers) {
    console.log('MediaStore setTeacherMediaCurrentScalabilityLayer', { consumerId, layers });
    const { ...teacherMedia } = this.teacherMedia;
    if (teacherMedia) {
      const mediaType = Object.values(teacherMedia.producers).find(
        info => info.consumerId === consumerId,
      )?.type;
      if (mediaType && mediaType in teacherMedia.media) {
        teacherMedia.media = {
          ...teacherMedia.media,
          [mediaType]: {
            ...teacherMedia.media[mediaType],
            scalabilityLayers: {
              ...teacherMedia.media[mediaType].scalabilityLayers,
              currentSpatialLayer: layers.spatialLayer,
              currentTemporalLayer: layers.temporalLayer,
            },
          },
        };
      }
      this.teacherMedia = teacherMedia;
    }
    console.log('MediaStore after setTeacherMediaCurrentScalabilityLayer', toJS(this.teacherMedia));
  }

  setTeacherMediaPreferredScalabilityLayer(consumerId, layers) {
    console.log('MediaStore setTeacherMediaPreferredScalabilityLayer', {
      consumerId,
      layers,
    });
    const { ...teacherMedia } = this.teacherMedia;
    if (teacherMedia) {
      const mediaType = Object.values(teacherMedia.producers).find(
        info => info.consumerId === consumerId,
      )?.type;
      if (mediaType && mediaType in teacherMedia.media) {
        teacherMedia.media = {
          ...teacherMedia.media,
          [mediaType]: {
            ...teacherMedia.media[mediaType],
            scalabilityLayers: {
              ...teacherMedia.media[mediaType].scalabilityLayers,
              preferredSpatialLayer: layers.spatialLayer,
              preferredTemporalLayer: layers.temporalLayer,
            },
          },
        };
      }
      this.teacherMedia = teacherMedia;
    }
    console.log(
      'MediaStore after setTeacherMediaPreferredScalabilityLayer',
      toJS(this.teacherMedia),
    );
  }

  get selectedMedia() {
    let selectedMedia = null;
    if (this.selectedMediaPeerId) {
      selectedMedia = this.remoteMedia.get(this.selectedMediaPeerId) ?? null;
    }
    return selectedMedia;
  }

  selectMedia = peerId => {
    console.log('MediaStore select media');
    this.selectedMediaPeerId = peerId;
    this.lastSelectedMediaPeerId = peerId;
  };

  unselectMedia = () => {
    this.selectedMediaPeerId = null;
  };

  setLocalMediaTrack(producer, mediaType) {
    // eslint-disable-next-line no-console
    console.log('mediaStore setLocalMediaTrack', { producer, mediaType });
    const { track, id: producerId } = producer;
    const { ...localMedia } = this.localMedia;
    if (mediaType === mediaTypes.videoType) {
      localMedia.isVideo = true;
    } else if (mediaType === mediaTypes.audioType) {
      localMedia.isAudio = true;
    } else if (mediaType === mediaTypes.screenType) {
      localMedia.isScreenShare = true;
    }
    localMedia[mediaType] = { type: mediaType, track, producerId, producer };

    this.localMedia = localMedia;
    // eslint-disable-next-line no-console
    console.log('mediaStore after setLocalMediaTrack', toJS(this.localMedia));
  }

  removeLocalMediaTrack(producerId) {
    // eslint-disable-next-line no-console
    console.log('mediaStore removeLocalMediaTrack', { producerId });
    let mediaType;
    Object.keys(this.localMedia).forEach(key => {
      const { type, producerId: mediaTypeProducerId, producer } = this.localMedia[key];
      if (mediaTypeProducerId === producerId) {
        mediaType = type;
        producer.close();
      }
    });

    if (mediaType) {
      const { [mediaType]: removedLocalMedia, ...localMedia } = this.localMedia;
      if (mediaType === mediaTypes.videoType) {
        localMedia.isVideo = false;
      } else if (mediaType === mediaTypes.audioType) {
        localMedia.isAudio = false;
      } else if (mediaType === mediaTypes.screenType) {
        localMedia.isScreenShare = false;
      }
      this.localMedia = localMedia;
    }
    console.log('mediaStore after removeLocalMediaTrack', toJS(this.localMedia));
  }

  createRemoteMediaBlockedStreams(peerId, mediaType, streamsBlockingFlag) {
    console.log('MediaStore createRemoteMediaBlockedStreams', {
      peerId,
      mediaType,
      streamsBlockingFlag,
    });
    if (this.remoteMedia.has(peerId)) {
      const { ...remoteMedia } = this.remoteMedia.get(peerId);
      remoteMedia.blockedStreams = this.createBlockedStreams(
        remoteMedia,
        mediaType,
        streamsBlockingFlag,
      );
      this.remoteMedia.set(peerId, remoteMedia);
      console.log('MediaStore after createRemoteMediaBlockedStreams', toJS(remoteMedia));
    }
  }

  createTeacherBlockedStreams(mediaType, streamsBlockingFlag) {
    console.log('MediaStore createTeacherMediaBlockedStreams', { mediaType, streamsBlockingFlag });
    if (this.teacherMedia) {
      const { ...teacherMedia } = this.teacherMedia;
      teacherMedia.blockedStreams = this.createBlockedStreams(
        teacherMedia,
        mediaType,
        streamsBlockingFlag,
      );
      this.teacherMedia = teacherMedia;
      console.log('MediaStore after createTeacherMediaBlockedStreams', toJS(this.teacherMedia));
    }
  }

  removeRemoteMediaBlockedStreams(peerId, mediaType, streamsBlockingFlag) {
    console.log('MediaStore removeRemoteMediaBlockedStreams', {
      peerId,
      mediaType,
      streamsBlockingFlag,
    });
    if (this.remoteMedia.has(peerId)) {
      const { ...remoteMedia } = this.remoteMedia.get(peerId);
      remoteMedia.blockedStreams = this.removeBlockedStreams(
        remoteMedia,
        mediaType,
        streamsBlockingFlag,
      );
      this.remoteMedia.set(peerId, remoteMedia);
      console.log('MediaStore after removeRemoteMediaBlockedStreams', toJS(remoteMedia));
    }
  }

  removeTeacherBlockedStreams(mediaType, streamsBlockingFlag) {
    console.log('MediaStore removeTeacherBlockedStreams', { mediaType, streamsBlockingFlag });
    if (this.teacherMedia) {
      const { ...teacherMedia } = this.teacherMedia;
      teacherMedia.blockedStreams = this.removeBlockedStreams(
        teacherMedia,
        mediaType,
        streamsBlockingFlag,
      );
      this.teacherMedia = teacherMedia;
      console.log('MediaStore after removeTeacherBlockedStreams', toJS(this.teacherMedia));
    }
  }

  setRemoteMediaBlockedStreams(peerId, producerId, mediaType) {
    console.log('MediaStore setRemoteMediaBlockedStreams', { peerId, producerId, mediaType });
    const { ...remoteMedia } = this.remoteMedia.get(peerId);
    if (remoteMedia) {
      remoteMedia.blockedStreams = this.setBlockedStreams(remoteMedia.blockedStreams, mediaType, {
        producerId,
      });
      this.remoteMedia.set(peerId, remoteMedia);
      console.log('MediaStore after setRemoteMediaBlockedStreams', toJS(remoteMedia));
    }
  }

  setTeacherBlockedStreams(producerId, mediaType) {
    console.log('MediaStore setTeacherBlockedStreams', { producerId, mediaType });
    const { ...teacherMedia } = this.teacherMedia;
    if (teacherMedia) {
      teacherMedia.blockedStreams = this.setBlockedStreams(teacherMedia.blockedStreams, mediaType, {
        producerId,
      });
      this.teacherMedia = teacherMedia;
      console.log('MediaStore after setTeacherBlockedStreams', toJS(teacherMedia));
    }
  }

  setRemoteMediaStreamsBlockingFlag(peerId, mediaType, flag) {
    console.log('MediaStore setRemoteMediaStreamsBlockingFlag', { peerId, mediaType, flag });
    if (this.remoteMedia.has(peerId)) {
      const { ...remoteMedia } = this.remoteMedia.get(peerId);
      remoteMedia.blockedStreams = this.setStreamsBlockingFlag(
        remoteMedia.blockedStreams,
        mediaType,
        flag,
      );
      this.remoteMedia.set(peerId, remoteMedia);
      console.log(
        'MediaStore after setRemoteMediaStreamsBlockingFlag',
        toJS(this.remoteMedia.get(peerId)),
      );
    }
  }

  setTeacherStreamsBlockingFlag(mediaType, flag) {
    console.log('MediaStore setTeacherStreamsBlockingFlag', { mediaType, flag });
    if (this.teacherMedia) {
      const { ...teacherMedia } = this.teacherMedia;
      teacherMedia.blockedStreams = this.setStreamsBlockingFlag(
        teacherMedia.blockedStreams,
        mediaType,
        flag,
      );
      this.teacherMedia = teacherMedia;
      console.log('MediaStore after setTeacherStreamsBlockingFlag', toJS(this.teacherMedia));
    }
  }

  createBlockedStreams = (mediaObject, mediaType, streamsBlockingFlag) => {
    console.log('MediaStore createBlockedStreams', { mediaObject, mediaType });
    const {
      blockedStreams,
      media: { [mediaType]: media },
    } = mediaObject;
    let updatedBlockedStreams = { ...blockedStreams };
    if (typeof streamsBlockingFlag === 'boolean') {
      updatedBlockedStreams = this.setStreamsBlockingFlag(
        updatedBlockedStreams,
        mediaType,
        streamsBlockingFlag,
      );
    }
    const { producerId } = media ?? {};
    const { [producerId]: producerInfo } = mediaObject.producers;
    const { consumerId } = producerInfo ?? {};

    updatedBlockedStreams = this.setBlockedStreams(updatedBlockedStreams, mediaType, {
      consumerId,
      producerId,
    });

    console.log('MediaStore createBlockedStreams return', toJS(updatedBlockedStreams));
    return updatedBlockedStreams;
  };

  removeBlockedStreams = (mediaObject, mediaType, streamsBlockingFlag) => {
    console.log('MediaStore removeBlockedStreams', { mediaObject, mediaType });
    const { blockedStreams } = mediaObject;
    let updatedBlockedStreams = { ...blockedStreams };
    if (typeof streamsBlockingFlag === 'boolean') {
      updatedBlockedStreams = this.setStreamsBlockingFlag(
        updatedBlockedStreams,
        mediaType,
        streamsBlockingFlag,
      );
    }
    const { [mediaType]: removedCurrentBlockedStreams, ...currentBlockedStreams } =
      updatedBlockedStreams.currentBlockedStreams;
    updatedBlockedStreams.currentBlockedStreams = currentBlockedStreams;

    this._updateCurrentBlockedStreamFlag.call(
      updatedBlockedStreams.currentBlockedStreams,
      mediaType,
    );

    console.log('MediaStore removeBlockedStreams return', toJS(updatedBlockedStreams));
    return updatedBlockedStreams;
  };

  setBlockedStreams = (blockedStreams, mediaType, blockedStreamsData) => {
    console.log('MediaStore setBlockedStreams', { blockedStreams, mediaType, blockedStreamsData });
    const { ...updatedBlockedStreams } = blockedStreams;
    updatedBlockedStreams.currentBlockedStreams = {
      ...blockedStreams.currentBlockedStreams,
      [mediaType]: { ...blockedStreams.currentBlockedStreams[mediaType], ...blockedStreamsData },
    };
    this._updateCurrentBlockedStreamFlag.call(
      updatedBlockedStreams.currentBlockedStreams,
      mediaType,
    );
    console.log('MediaStore return setBlockedStreams', updatedBlockedStreams);
    return updatedBlockedStreams;
  };

  setStreamsBlockingFlag = (blockedStreams, mediaType, flag) => {
    console.log('MediaStore setStreamsBlockingFlag', { blockedStreams, mediaType, flag });
    const { ...updatedBlockedStreams } = blockedStreams;
    updatedBlockedStreams.streamsBlocking = {
      ...blockedStreams.streamsBlocking,
      [mediaType]: flag,
    };

    console.log('MediaStore setStreamsBlockingFlag return', toJS(updatedBlockedStreams));
    return updatedBlockedStreams;
  };

  _updateCurrentBlockedStreamFlag(mediaType) {
    const isStreamBlocked = this[mediaType]?.producerId !== undefined;
    switch (mediaType) {
      case mediaTypes.audioType:
        this.isAudio = isStreamBlocked;
        break;
      case mediaTypes.videoType:
        this.isVideo = isStreamBlocked;
        break;
      case mediaTypes.screenType:
        this.isScreenShare = isStreamBlocked;
        break;
      // no default
    }
  }

  getRemoteMedias = () =>
    Array.from(this.remoteMedia).map(([peerId, remoteMedia]) => ({
      ...remoteMedia,
      peerId,
      peerType: 'remoteMedia',
      isTeacherPeerType: false,
      isSelected: this.selectedMediaPeerId === peerId,
      isLastSelected: this.lastSelectedMediaPeerId === peerId,
    }));

  getTeacherMedia = () =>
    this.teacherMedia
      ? { ...this.teacherMedia, peerType: 'teacherMedia', isTeacherPeerType: true }
      : null;

  getAllRemoteMedias = () => {
    const remoteMedias = this.getRemoteMedias();
    const teacherMedia = this.getTeacherMedia();
    const allMedias = [...remoteMedias];
    if (teacherMedia) {
      allMedias.push(teacherMedia);
    }
    return allMedias;
  };

  countTracksAmount = media => {
    const { isAudio, isVideo, isScreenShare } = media ?? {};
    let tracksAmount = 0;
    if (isAudio) {
      tracksAmount += 1;
    }
    if (isVideo) {
      tracksAmount += 1;
    }
    if (isScreenShare) {
      tracksAmount += 1;
    }
    return tracksAmount;
  };

  get volumeZeroToOneRange() {
    return this.volume / 100;
  }

  setVolume = value => {
    this.volume = value;
  };

  clearAll() {
    this.remoteMedia = new Map();
    this.localMedia = { isVideo: false, isAudio: false, isScreenShare: false };
    this.selectedMediaPeerId = null;
    this.teacherMedia = null;
  }
}

export default MediaStore;
