import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import Error from '../views/Error/Error';
import ReloadApp from './Modal/Reload/ReloadApp/ReloadApp';

function ErrorHandler({ children }) {
  const location = useLocation();
  const { state } = location;
  const errorStatusCode = state?.errorStatusCode;
  const errorMessage = state?.errorMessage;

  if (errorStatusCode === 500) {
    return <ReloadApp />;
  }

  if (errorStatusCode === 404 || errorStatusCode === 403) {
    return (
      <>
        <Error type={errorStatusCode} hintMessage={errorMessage} />
      </>
    );
  }

  return children;
}

ErrorHandler.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorHandler;
