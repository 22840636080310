import { toast } from 'react-toastify';

function showedAttentionNotification(payload) {
  const { attention_type: audioType } = payload.lesson;
  const { openReaction } = this.modalWindowsStore;
  const { isStudent } = this.roleInLesson;

  const audio = {
    attention1: 'металлофон',
    attention2: 'поезд',
    attention3: 'барабаны',
    attention4: 'симфония №5',
  };

  if (isStudent) {
    openReaction(true, audioType);
  } else {
    toast.info(`Вы отправили сигнал «${audio[audioType]}»`);
  }
}

export default showedAttentionNotification;
