import { makeAutoObservable } from 'mobx';

class ButtonsStore {
  constructor() {
    this.toiletButton = false;
    this.handUpButton = true;
    this.firstButton = null;
    this.secondButton = null;
    this.isTopicButtonsDisplayed = false;
    this.isGlobalTopicButtonEnabled = false;
    this.studentsTopicButtons = {};
    this.isYesNoButtonsDisplayed = false;
    this.isCameraPressed = false;
    this.isAudioPressed = false;
    this.emojiPreloader = false;

    makeAutoObservable(this, {
      handUpButton: false,
    });
  }

  setFirstButton = (name, image) => {
    this.firstButton = { name, image };
  };

  setSecondButton = (name, image) => {
    this.secondButton = { name, image };
  };

  setToiletButton = value => {
    this.toiletButton = value;
  };

  displayTopicButtons = value => {
    this.isTopicButtonsDisplayed = value;
  };

  setGlobalTopicButtonState = value => {
    this.isGlobalTopicButtonEnabled = value;
  };

  setStudentsTopicButton = (peerId, value) => {
    const { ...studentsTopicButtons } = this.studentsTopicButtons;
    studentsTopicButtons[peerId] = value;
    this.studentsTopicButtons = studentsTopicButtons;
  };

  displayYesNoButtons = value => {
    this.isYesNoButtonsDisplayed = value;
  };

  setIsCameraPressed = value => {
    this.isCameraPressed = value;
  };

  setIsAudioPressed = value => {
    this.isAudioPressed = value;
  };

  setEmojiPreloader = value => {
    this.emojiPreloader = value;
    const timerId = setTimeout(() => {
      this.setEmojiPreloader(false);
      clearTimeout(timerId);
    }, 5000);
  };
}

export default ButtonsStore;
