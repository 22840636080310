import { makeAutoObservable } from 'mobx';
import localStorage from '../utils/localStorage';

class UserStore {
  constructor({ api }) {
    this.api = api;
    this.isAuthorized = localStorage.accessToken;
    this.updateFromLocalStorage = new Date().getTime();
    makeAutoObservable(this, {
      api: false,
    });
  }

  setIsAuthorized = value => {
    this.isAuthorized = value;
  };

  /**
   * Used to trigger cross-component event to update states from localStorage.
   */
  setUpdateFromLocalStorage = () => {
    this.updateFromLocalStorage = new Date().getTime();
  };
}

export default UserStore;
