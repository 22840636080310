import apiURLs from '../../constants/apiURLs';
import localStorage from '../../utils/localStorage';

class ProfileEndpoint {
  constructor(axios, setBearer, removeBearer) {
    this.axios = axios;
    this.setBearer = setBearer;
    this.removeBearer = removeBearer;
  }

  getUserProfile = async (role, id) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.profile(role.toLowerCase(), id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  postUserProfile = async (data, role, id) => {
    try {
      await this.axios({
        method: 'POST',
        url: apiURLs.profile(role.toLowerCase(), id),
        data,
      });
    } catch (error) {
      throw error.response;
    }
  };

  putTimezoneAdmin = async data => {
    try {
      await this.axios({
        method: 'PUT',
        url: apiURLs.timezoneAdmin,
        data,
      });
    } catch (error) {
      throw error.response;
    }
  };

  getStudentClasses = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.educationClasses,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getStudentFormats = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.educationFormats,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getAdultRoles = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.adultRoles,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  searchCity = async city_name => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.searchCity(city_name),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  searchTimezone = async (city_name, city_latitude, city_longitude) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.searchTimezone(city_name, city_latitude, city_longitude),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  deleteUser = async () => {
    try {
      const response = await this.axios({
        method: 'DELETE',
        url: apiURLs.deleteAccount,
      });
      localStorage.deleteAll();
      this.removeBearer();
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  changePassword = async data => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.changePassword,
        data,
      });
      localStorage.deleteAll();
      this.removeBearer();
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  recoveryPassword = async data => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.recoveryPassword,
        data: { email: data },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  restorePassword = async (data, token) => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.restorePassword,
        data: {
          token,
          new_password: data.new_password,
          new_password_confirmation: data.new_password_confirmation,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  restoreAccount = async token => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.restoreAccount,
        data: {
          token,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  changeEmailConfirmation = async token => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.changeEmailConfirmation,
        data: {
          token,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  checkMe = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.me,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getEducationFormTemplates = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getEducationFormTemplates,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getOrdersList = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getOrdersList,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getNotification = async (page, timezone) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getNotification,
        params: {
          page,
          per_page: 10,
          timezone,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  readNotification = async id => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.readNotification(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  divergenciesList = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.divergenciesList,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}

export default ProfileEndpoint;
