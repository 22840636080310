import React from 'react';
import PropTypes from 'prop-types';

// import Sidebar from '../Sidebar/Sidebar';

export default function Container({ children, classname }) {
  return (
    <>
      {/* <Sidebar /> */}
      <div className={classname}>{children}</div>
    </>
  );
}

Container.propTypes = {
  classname: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

Container.defaultProps = {
  classname: '',
  children: null,
};
