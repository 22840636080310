import React, { createContext, useContext } from 'react';

import RootStore from './RootStore';

const StoreContext = createContext('store');

let store;

const createStore = () => {
  store = new RootStore({});

  return ({ children }) => <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

const useStore = () => useContext(StoreContext);

export { createStore, useStore, store };
