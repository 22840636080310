import history from '../../../utils/history/history';
import navigation from '../../../constants/navigation';

async function failureSocketRequest(action, data) {
  const { name } = data;
  switch (action) {
    case this.actions.joinLesson:
      switch (name) {
        case this.errors.lessonHasNotBeenStartedError:
          history.push(navigation.lessonWaiting.pathWithSearch);
          break;
        case this.errors.lessonHasBeenClosedError: {
          history.push(navigation.lessonEnd.pathWithSearch);
          const { pathname, search } = history.location;
          history.replace({ pathname, search }, { errorStatusCode: 404 });
          break;
        }
        case this.errors.accessDeniedError: {
          const { code, message } = data.data;
          const { pathname, search } = history.location;
          const errorMessage = code === 403 ? message : '';
          history.replace({ pathname, search }, { errorStatusCode: code, errorMessage });
          break;
        }
        // no default
      }
      break;
    // no default
  }
}

export default failureSocketRequest;
