import { mediaTypes } from '../constants/mediaTypes';

function mediaTypeAdapter(mediaType) {
  switch (mediaType) {
    case 'microphone':
      return mediaTypes.audioType;
    case 'camera':
      return mediaTypes.videoType;
    case 'screen':
      return mediaTypes.screenType;
    default:
      return null;
  }
}

export default mediaTypeAdapter;
