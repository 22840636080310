function onEnterKeyDecorator(callback) {
  return (event, ...args) => {
    const { type: eventType, key: eventKey } = event;
    const isKeypressEventType = eventType === 'keypress';
    const isEnterEventKey = eventKey === 'Enter';
    const isClickEventType = eventType === 'click';
    const isKeyEnter = isKeypressEventType && isEnterEventKey;
    if (isKeyEnter || isClickEventType) {
      callback(event, ...args);
    }
  };
}

export default onEnterKeyDecorator;
