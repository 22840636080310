import { makeAutoObservable } from 'mobx';

class ModalWindowsStore {
  constructor() {
    this.isOpenMediaAccess = false;
    this.isOpenMediaAccessDenied = false;
    this.isOpenFinishLessonBreak = false;
    this.isOpenFinishLesson = false;
    this.emotion = null;
    this.isOpenFaq = false;
    this.isShareScreen = false;
    this.mediaState = null;
    this.yesNoReaction = null;
    this.attentionChoiceModal = null;
    this.emojiChoiceModal = null;
    this.reaction = null;
    this.isNotificationOpen = false;
    this.notification = null;
    this.reloadApp = false;
    this.reloadSocketConnection = false;
    this.isOpenJoinLesson = false;
    this.stopReactionPreloader = false;
    this.attentionPreloader = false;
    this.ringPreloader = false;
    this.controlBlockPreloader = false;
    this.isOpenDeleteAccountModal = false;
    this.isOpenProfileExitModal = false;
    this.isCropperModalOpen = false;
    this.isAdultCropperModalOpen = false;
    this.isEducationInfoModalOpen = false;
    this.isMaterialCountModalOpen = false;
    this.isPictureModalOpen = null;
    this.isMakeOrderModalOpen = null;
    this.isMyProgressUploadModalOpen = false;
    this.isDeleteResourceModalOpen = null;
    this.isMyProgressFolderModalOpen = null;
    this.isTimeSheetCreateModalOpen = null;
    this.isTimeSheetDeleteModalOpen = null;
    this.isChangeEventStatusModalOpen = null;
    this.isLeavePageModalOpen = false;
    this.isDownloadTimeSheetModalOpen = null;
    makeAutoObservable(this);
  }

  openMediaAccess = (value = true) => {
    this.isOpenMediaAccess = value;
  };

  openMediaAccessDenied = (value = true) => {
    this.isOpenMediaAccessDenied = value;
  };

  openFinishLessonBreak = value => {
    this.isOpenFinishLessonBreak = value;
  };

  openFinishLesson = value => {
    this.isOpenFinishLesson = value;
  };

  openEmotion = (value, type, icon) => {
    if (value) {
      this.emotion = { type, icon };
    } else {
      this.emotion = null;
    }
  };

  openFaq = value => {
    this.isOpenFaq = value;
  };

  openShareScreen = value => {
    this.isShareScreen = value;
  };

  openChangeMediaState = (value, type, isTurnOn) => {
    this.mediaState = value ? { type, isTurnOn } : null;
  };

  openYesNoReaction = (value, type, text) => {
    if (value) {
      this.yesNoReaction = { type, text };
    } else {
      this.yesNoReaction = null;
    }
  };

  openEmojiChoiceModal = (value, peerId, name) => {
    this.emojiChoiceModal = value ? { peerId, name } : null;
  };

  openAttentionChoiceModal = (value, peerId, name) => {
    this.attentionChoiceModal = value ? { peerId, name } : null;
  };

  openReaction = (value, type) => {
    this.reaction = value ? { type } : null;
  };

  setIsNotificationOpen = value => {
    this.isNotificationOpen = value;
  };

  setNotification = value => {
    this.notification = value;
  };

  openReloadApp = value => {
    this.reloadApp = value;
  };

  openReloadSocketConnection = value => {
    this.reloadSocketConnection = value;
  };

  openJoinLessonModal = value => {
    this.isOpenJoinLesson = value;
  };

  setStopReactionPreloader = value => {
    this.stopReactionPreloader = value;
  };

  setAttentionPreloader = value => {
    this.attentionPreloader = value;
  };

  setRingPreloader = value => {
    this.ringPreloader = value;
  };

  setControlBlockPreloader = (value, peerId) => {
    this.controlBlockPreloader = value ? { peerId } : null;
  };

  openDeleteAccountModal = (value = false) => {
    this.isOpenDeleteAccountModal = value;
  };

  openProfileExitModal = (value = false) => {
    this.isOpenProfileExitModal = value;
  };

  setIsCropperModalOpen = value => {
    this.isCropperModalOpen = value;
  };

  setIsAdultCropperModalOpen = value => {
    this.isAdultCropperModalOpen = value;
  };

  setIsEducationInfoModalOpen = (value, item) => {
    this.isEducationInfoModalOpen = value ? { item } : null;
  };

  setIsMaterialCountModalOpen = (value, item) => {
    this.isMaterialCountModalOpen = value ? { item } : null;
  };

  setIsPictureModalOpen = value => {
    this.isPictureModalOpen = value;
  };

  setIsMyProgressUploadModalOpen = value => {
    this.isMyProgressUploadModalOpen = value;
  };

  setIsDeleteResourceModalOpen = value => {
    this.isDeleteResourceModalOpen = value;
  };

  setIsMakeOrderModalOpen = (value, name, price, formInfo) => {
    this.isMakeOrderModalOpen = value ? { name, price, formInfo } : null;
  };

  setIsMyProgressFolderModalOpen = (value, item) => {
    this.isMyProgressFolderModalOpen = value ? { item } : null;
  };

  setIsTimeSheetCreateModalOpen = (value, item) => {
    this.isTimeSheetCreateModalOpen = value ? { item } : null;
  };

  setIsTimeSheetDeleteModalOpen = (value, id) => {
    this.isTimeSheetDeleteModalOpen = value ? { id } : null;
  };

  setIsChangeEventStatusModalOpen = (value, item) => {
    this.isChangeEventStatusModalOpen = value ? { item } : null;
  };

  setIsDownloadTimeSheetModalOpen = value => {
    this.isDownloadTimeSheetModalOpen = value;
  };

  setIsLeavePageModalOpen = value => {
    this.isLeavePageModalOpen = value;
  };
}

export default ModalWindowsStore;
