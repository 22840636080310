import React from 'react';
import { ReactComponent as Logo } from '../../assets/img/logo_type_1.svg';
import { ReactComponent as Smile } from './assets/smile.svg';
import mobileBack from './assets/mobileBack.png';

function MobilePlug() {
  return (
    <div className="mobile-plug">
      <Logo />
      <div>
        <p className="mobile-plug-text">
          Пожалуйста, зайдите в МОСТИК с вашего компьютера или планшета
        </p>
        <Smile />
        <p className="mobile-plug-text">Будем заниматься!</p>
      </div>
      <img src={mobileBack} alt="" />
    </div>
  );
}

export default MobilePlug;
