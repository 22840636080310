const scalabilityLayers = {
  low: 'low',
  middle: 'middle',
  high: 'high',
};

function getLayersByLevel(scalabilityLevel, layers) {
  const { spatialLayers, temporalLayers } = layers;
  const temporalLayer = temporalLayers - 1;
  const arr = [];
  for (let i = 0; i < spatialLayers; i += 1) {
    arr.push(i);
  }
  switch (scalabilityLevel) {
    case scalabilityLayers.low:
      return { spatialLayer: arr[0], temporalLayer };
    case scalabilityLayers.middle:
      return { spatialLayer: Math.ceil((arr.length - 1) / 2), temporalLayer };
    case scalabilityLayers.high:
      return { spatialLayer: arr[arr.length - 1], temporalLayer };
    default:
      return { spatialLayer: 0, temporalLayer: 0 };
  }
}

export { getLayersByLevel };
export default scalabilityLayers;
