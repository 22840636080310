import { toast } from 'react-toastify';
/* eslint-disable import/no-cycle */
import Socket from './socket/Socket';
import Mediasoup from './mediasoup/Mediasoup';
import MediasoupCanProduceError from './mediasoup/MediasoupError';
import MediaError from './media/MediaError';
import { store } from '../store';
import getSearchParams from '../utils/searchParams/getSearchParams';
import localStorage from '../utils/localStorage';
import videoConferenceSuccessSocketRequest from './socket/successSocketRequest/successSocketRequest';
import videoConferenceFailureSocketRequest from './socket/failureSocketRequest/failureSocketRequest';
import videoConferenceSocketEvent from './socket/socketEvent/socketEvent';
import chatSuccessSocketRequestCallback from './socket/chat/success/Success';
import chatFailureSocketRequestCallback from './socket/chat/failure/Failure';
import chatSocketEventCallback from './socket/chat/event/Event';
import { mediaTypes } from '../constants/mediaTypes';
import MessageAdapter from '../utils/adapters/MessageApapter';
import Queue from './Queue';
import history from '../utils/history/history';
import navigation from '../constants/navigation';
import objectToSearchParams from '../utils/searchParams/objectToSearchParams';
import scalabilityLayers, { getLayersByLevel } from '../constants/scalabilityLayers';
import isMobileOS from '../constants/isMobileOS';

// eslint-disable-next-line import/no-mutable-exports
let lesson = null;
const availableLessonRoles = { student: 'student', teacher: 'teacher' };

/**
 * @class Lesson
 * @property {Socket}  socket
 * @property {Mediasoup}  mediasoup
 */
class Lesson {
  constructor() {
    this.socket = null;
    this.mediasoup = null;
    this.queue = null;
    this.modalWindowsStore = null;
    this.buttonsStore = null;
    this.reactionStore = null;
    this.roleInLesson = {
      value: '',
      isTeacher: null,
      isStudent: null,
    };
    this.lessonHash = '';
    this.lessonName = '';
    this.teacherName = '';
    this.educationStakeholder = '';
    this.roomInfo = null;
    this.peerInfo = null;
    this.isIndividualLesson = null;
    this.isAllClassMode = null;
    this.teacherPeerId = '';
    this.isLessonInformationExist = false;
    this.isLessonOnPause = false;
    this.isReadyToHandleEvents = false;
    this.isRelevant = true;
    this.messageAdapter = '';
    this.errors = {
      lessonHasBeenStartedError: 'LessonHasBeenStartedError',
      lessonHasNotBeenStartedError: 'LessonHasNotBeenStartedError',
      lessonHasBeenClosedError: 'LessonHasBeenClosedError',
      lessonAlreadyContainsUser: 'LessonAlreadyContainsUser',
      accessDeniedError: 'AccessDeniedError',
    };
    this.actions = {
      joinLesson: 'joinLesson',
      getLessonInfo: 'getLessonInfo',
      getRoomInfo: 'getRoomInfo',
      startLesson: 'startLesson',
      endLesson: 'endLesson',
      startBreakLesson: 'startBreakLesson',
      stopBreakLesson: 'stopBreakLesson',
    };
    this.chatActions = {
      getMessagesChat: 'getMessagesChat',
      sendMessageChat: 'sendMessageChat',
      deleteMessageChat: 'deleteMessageChat',
      readMessageChat: 'readMessageChat',
    };
    this.chatEvents = {
      newMessageChat: 'newMessageChat',
    };
    this.events = {
      lessonIsStarted: 'lessonIsStarted',
      lessonIsEnded: 'lessonIsEnded',
      lessonBreakStarted: 'lessonBreakStarted',
      lessonBreakStopped: 'lessonBreakStopped',
      userJoinedToLesson: 'userJoinedToLesson',
      userLeaveFromLesson: 'userLeaveFromLesson',
      deletedMessageChat: 'deletedMessageChat',
      gotNewMessageChat: 'newMessageChat',
      disconnect: 'disconnect',
    };
    this.videoConferenceActions = {
      getRtpCapabilities: 'getRtpCapabilities',
      startStreamByCamera: 'startStreamByCamera',
      startStreamByMicrophone: 'startStreamByMicrophone',
      startShareScreen: 'startShareScreen',
      stopStreamByCamera: 'stopStreamByCamera',
      stopStreamByMicrophone: 'stopStreamByMicrophone',
      stopShareScreen: 'stopShareScreen',
      startConsumeStream: 'startConsumeStream',
      stopConsumeStream: 'stopConsumeStream',
      setLayers: 'setLayers',
    };
    this.videoConferenceEvents = {
      userStartedStream: 'userStartedStream',
      userStoppedStream: 'userStoppedStream',
      userConsumerIsClosed: 'userConsumerIsClosed',
      consumerLayersChanged: 'consumerLayersChanged',
    };
    this.videoConferenceButtonsEvents = {};
    this.videoConferenceTeacherAction = {
      showThemeButtons: 'showThemeButtons',
      hideThemeButtons: 'hideThemeButtons',
      showThemeButtonsTo: 'showThemeButtonsTo',
      hideThemeButtonsTo: 'hideThemeButtonsTo',
      firstThemeButtonReaction: 'firstThemeButtonReaction',
      secondThemeButtonReaction: 'secondThemeButtonReaction',
      handUp: 'handUp',
      toiletNeed: 'toiletNeed',
      showYesNoButtons: 'showYesNoButtons',
      showYesNoButtonsTo: 'showYesNoButtonsTo',
      yesButtonReaction: 'yesButtonReaction',
      noButtonReaction: 'noButtonReaction',
      attentionNotification: 'attentionNotification',
      attentionNotificationTo: 'attentionNotificationTo',
      emojiNotification: 'emojiNotification',
      emojiNotificationTo: 'emojiNotificationTo',
      stopNotification: 'stopNotification',
      stopNotificationTo: 'stopNotificationTo',
      callNotification: 'callNotification',
      startStreamByCameraTo: 'startStreamByCameraTo',
      stopStreamByCameraTo: 'stopStreamByCameraTo',
      startStreamByMicrophoneTo: 'startStreamByMicrophoneTo',
      stopStreamByMicrophoneTo: 'stopStreamByMicrophoneTo',
    };
    this.videoConferenceTeacherEvent = {
      showedThemeButtons: 'showedThemeButtons',
      hiddenThemeButtons: 'hiddenThemeButtons',
      showedFirstThemeButtonReaction: 'showedFirstThemeButtonReaction',
      showedSecondThemeButtonReaction: 'showedSecondThemeButtonReaction',
      toiletNeeded: 'toiletNeeded',
      handUpped: 'handUpped',
      showedYesNoButtons: 'showedYesNoButtons',
      showedYesButtonReaction: 'showedYesButtonReaction',
      showedNoButtonReaction: 'showedNoButtonReaction',
      showedAttentionNotification: 'showedAttentionNotification',
      showedEmojiNotification: 'showedEmojiNotification',
      showedStopNotification: 'showedStopNotification',
      showedCallNotification: 'showedCallNotification',
      startStreamByCameraNotification: 'startStreamByCameraNotification',
      stopStreamByCameraNotification: 'stopStreamByCameraNotification',
      startStreamByMicrophoneNotification: 'startStreamByMicrophoneNotification',
      stopStreamByMicrophoneNotification: 'stopStreamByMicrophoneNotification',
    };
    this.oppositeEventNames = {
      [this.videoConferenceEvents.userStartedStream]: this.videoConferenceEvents.userStoppedStream,
      [this.events.userJoinedToLesson]: this.events.userLeaveFromLesson,
    };
    this.videoConferenceSuccessSocketRequest = videoConferenceSuccessSocketRequest.bind(this);
    this.videoConferenceFailureSocketRequest = videoConferenceFailureSocketRequest.bind(this);
    this.videoConferenceSocketEvent = videoConferenceSocketEvent.bind(this);

    this.chatSuccessSocketRequestCallback = chatSuccessSocketRequestCallback.bind(this);
    this.chatFailureSocketRequestCallback = chatFailureSocketRequestCallback.bind(this);
    this.chatSocketEventCallback = chatSocketEventCallback.bind(this);
    this._init();
    lesson = this;
  }

  async connect() {
    console.log('Lesson connect');
    this.socket.setSuccessSocketRequestCallback(
      this.videoConferenceSuccessSocketRequest.name,
      this.videoConferenceSuccessSocketRequest,
    );
    this.socket.setFailureSocketRequestCallback(
      this.videoConferenceFailureSocketRequest.name,
      this.videoConferenceFailureSocketRequest,
    );
    this.socket.setSocketEventCallback(
      this.videoConferenceSocketEvent.name,
      this.videoConferenceSocketEvent,
    );

    // Register Chat Callbacks
    this.socket.setSuccessSocketRequestCallback(
      this.chatSuccessSocketRequestCallback.name,
      this.chatSuccessSocketRequestCallback,
    );
    this.socket.setFailureSocketRequestCallback(
      this.chatFailureSocketRequestCallback.name,
      this.chatFailureSocketRequestCallback,
    );
    this.socket.setSocketEventCallback(
      this.chatSocketEventCallback.name,
      this.chatSocketEventCallback,
    );

    this.socket.setSuccessSocketReconnectCallback(this.connect.name, async () =>
      this._joinLesson(true),
    );
    this.socket.setSocketDisconnectCallback(this._removePeersInfo.name, () => {
      this._removePeersInfo();
    });

    try {
      await this.socket.connect(this.roleInLesson.isTeacher);
      await this.socket.request(this.actions.getLessonInfo);
      await this._joinLesson(false);
    } catch (e) {
      console.error(e);
    }
  }

  async startLesson() {
    console.log('Lesson startLesson');
    await this.socket.request(this.actions.startLesson);
  }

  async pauseLesson() {
    console.log('Lesson breakLesson');
    await this.socket.request(this.actions.startBreakLesson);
  }

  async resumeLesson() {
    console.log('Lesson resumeLesson');
    await this.socket.request(this.actions.stopBreakLesson);
  }

  async stopLesson() {
    console.log('Lesson stopLesson');
    await this.socket.request(this.actions.endLesson);
  }

  exitFromLesson = (isDisconnectSocket = false) => {
    console.log('Lesson exitFromLesson', { isDisconnectSocket });
    if (this) {
      if (isDisconnectSocket) {
        this.socket.disconnect();
      }
      this.mediasoup.stopMediaTransfer();
      this._clearData();
    }
  };

  async startLocalMedia(isShowErrorNotification = true, isKeepingPreviousState = false) {
    const promiseSettledArray = [
      this._startLocalAudio(isKeepingPreviousState),
      this._startLocalVideo(isKeepingPreviousState),
    ];
    if (!isMobileOS) {
      promiseSettledArray.push(this._startScreenShare());
    }
    await Promise.allSettled(promiseSettledArray).then(results => {
      let isSomeRejected = false;
      let isMediaAccessError = false;
      const mediasoupProduceErrors = [];
      const defaultErrorMessages = [];
      results.forEach(result => {
        const { status, reason } = result;
        if (status === 'rejected') {
          const { message } = reason;
          isSomeRejected = true;
          if (reason instanceof MediaError) {
            isMediaAccessError = true;
          } else if (reason instanceof MediasoupCanProduceError) {
            mediasoupProduceErrors.push(reason);
          } else {
            defaultErrorMessages.push(message);
          }
        }
      });
      if (isSomeRejected && isShowErrorNotification) {
        if (isMediaAccessError) {
          this.modalWindowsStore.openMediaAccessDenied(true);
        } else if (mediasoupProduceErrors.length) {
          mediasoupProduceErrors.forEach(mediasoupError => {
            const { message } = mediasoupError;
            toast.error(message);
          });
        } else {
          defaultErrorMessages.forEach(defaultErrorMessage => {
            toast.error(defaultErrorMessage);
          });
        }
      }
    });
  }

  _startLocalAudio = async (isKeepingPreviousState = false) => {
    await this.mediasoup.produce(mediaTypes.audioType, isKeepingPreviousState);
  };

  _startLocalVideo = async (isKeepingPreviousState = false) => {
    await this.mediasoup.produce(mediaTypes.videoType, isKeepingPreviousState);
  };

  _startScreenShare = async () => {
    await this.mediasoup.produce(mediaTypes.screenType, true);
  };

  async pauseLocalAudio() {
    console.log('Lesson pauseLocalAudio');
    const { roomId } = this.roomInfo;
    const { producerId } = this.mediasoup.mediaStore.localMedia[mediaTypes.audioType];
    await this.socket.request(this.videoConferenceActions.stopStreamByMicrophone, {
      roomId,
      producerId,
    });
    this.mediasoup.closeProducer(producerId);
  }

  async pauseLocalVideo() {
    console.log('Lesson pauseLocalVideo');
    const { roomId } = this.roomInfo;
    const { producerId } = this.mediasoup.mediaStore.localMedia[mediaTypes.videoType];
    await this.socket.request(this.videoConferenceActions.stopStreamByCamera, {
      roomId,
      producerId,
    });
    this.mediasoup.closeProducer(producerId);
  }

  async resumeLocalAudio() {
    console.log('Lesson resumeLocalAudio');
    try {
      await this._startLocalAudio();
    } catch (error) {
      const errorMessage = error?.message;
      if (error instanceof MediaError) {
        this.modalWindowsStore.openMediaAccessDenied(true);
      } else {
        toast.error(errorMessage);
      }
    }
  }

  async resumeLocalVideo() {
    console.log('Lesson resumeLocalVideo');
    try {
      const { isScreenShare } = await this.mediasoup.produce(mediaTypes.videoType);
      if (isScreenShare) {
        console.log('isScreenShare');
        // await this.stopScreenShare();
      }
    } catch (error) {
      const errorMessage = error?.message;
      if (error instanceof MediaError) {
        this.modalWindowsStore.openMediaAccessDenied(true);
      } else {
        toast.error(errorMessage);
      }
    }
  }

  stopGettingMediaThreads(excludedPeerId, mediaType) {
    console.log('stopGettingMediaThreads', { excludedPeerId });
    const { roomId } = this.roomInfo;
    const allRemoteMedias = this.mediasoup.getRemoteMedias();
    allRemoteMedias.forEach(remoteMedia => {
      const { peerId, isTeacherPeerType } = remoteMedia;
      if (peerId !== excludedPeerId) {
        this.queue.pushTask(peerId, async () => {
          await this.mediasoup.stopRemoteMediaLocally(roomId, peerId, mediaType, isTeacherPeerType);
          this.queue.resumeTaskExecuting(peerId);
        });
      }
    });
  }

  resumeGettingMediaThreads(excludedPeerId, mediaType) {
    console.log('resumeGettingMediaThreads', { excludedPeerId });
    const { roomId } = this.roomInfo;
    const allRemoteMedias = this.mediasoup.getRemoteMedias();
    allRemoteMedias.forEach(remoteMedia => {
      const { peerId, isTeacherPeerType } = remoteMedia;
      if (peerId !== excludedPeerId) {
        const scalabilityLevel = this.isAllClassMode
          ? scalabilityLayers.low
          : scalabilityLayers.high;
        this.queue.pushTask(peerId, async () => {
          await this.mediasoup.resumeRemoteMediaLocally(
            roomId,
            peerId,
            mediaType,
            isTeacherPeerType,
            scalabilityLevel,
          );
          this.queue.resumeTaskExecuting(peerId);
        });
      }
    });
  }

  stopGettingCertainMediaThread(peerId, mediaType) {
    console.log('stopGettingCertainMediaThread', { peerId, mediaType });
    const { roomId } = this.roomInfo;
    const remoteMedia = this.mediasoup.getRemoteMedia(peerId);
    if (remoteMedia) {
      const { isTeacherPeerType } = remoteMedia;
      this.queue.pushTask(peerId, async () => {
        await this.mediasoup.stopRemoteMediaLocally(roomId, peerId, mediaType, isTeacherPeerType);
        this.queue.resumeTaskExecuting(peerId);
      });
    }
  }

  resumeGettingCertainMediaThread(peerId, mediaType) {
    console.log('resumeGettingCertainMediaThread', { peerId, mediaType });
    const { roomId } = this.roomInfo;
    const remoteMedia = this.mediasoup.getRemoteMedia(peerId);
    if (remoteMedia) {
      const { isTeacherPeerType } = remoteMedia;
      const scalabilityLevel = this.isAllClassMode ? scalabilityLayers.low : scalabilityLayers.high;
      this.queue.pushTask(peerId, async () => {
        await this.mediasoup.resumeRemoteMediaLocally(
          roomId,
          peerId,
          mediaType,
          isTeacherPeerType,
          scalabilityLevel,
        );
        this.queue.resumeTaskExecuting(peerId);
      });
    }
  }

  forcedStopGettingMediaThreads(mediaType) {
    console.log('lesson forcedStopGettingMediaThreads', { mediaType });
    const { isStudent } = this.roleInLesson;
    const excludedPeerId = isStudent ? this.teacherPeerId : null;
    this.mediasoup.setForcedBlockedMediaStreams(mediaType);
    this.mediasoup.setNewMediaBlockedStreams({ [mediaType]: true });
    this.stopGettingMediaThreads(excludedPeerId, mediaType);
  }

  forcedResumeGettingMediaThreads(mediaType) {
    console.log('Lesson forcedResumeGettingMediaThreads', { mediaType });
    this.mediasoup.removeForcedBlockedMediaStreams(mediaType);
    this.mediasoup.setNewMediaBlockedStreams({ [mediaType]: false });
    this.resumeGettingMediaThreads(null, mediaType);
  }

  setAllClassMode(allClassModeFlag, blockedMediaType, excludedPeerId) {
    console.log('Lesson setAllClassMode', {
      allClassModeFlag,
      blockedMediaType,
      excludedPeerId,
    });
    this.isAllClassMode = allClassModeFlag;
    const forcedBlockedMediaStreams = this.mediasoup.getForcedBlockedMediaStreams();

    const getLastSelectedMedia = () =>
      this.mediasoup.getRemoteMedias().find(({ isLastSelected }) => isLastSelected);

    const getSelectedMedia = () =>
      this.mediasoup.getRemoteMedias().find(remoteMedia => remoteMedia.isSelected);

    if (!forcedBlockedMediaStreams[blockedMediaType]) {
      this.mediasoup.setNewMediaBlockedStreams({ [blockedMediaType]: !allClassModeFlag });
      if (allClassModeFlag) {
        this.resumeGettingMediaThreads(null, blockedMediaType);
      } else {
        this.stopGettingMediaThreads(excludedPeerId, blockedMediaType);
      }
    } else if (allClassModeFlag) {
      const lastSelectedMedia = getLastSelectedMedia();
      if (lastSelectedMedia) {
        const { peerId } = lastSelectedMedia;
        this.stopGettingCertainMediaThread(peerId, blockedMediaType);
      }
    } else {
      const selectedMedia = getSelectedMedia();
      if (selectedMedia) {
        const { peerId } = selectedMedia;
        this.resumeGettingCertainMediaThread(peerId, blockedMediaType);
      }
    }
  }

  async setPreferredScalabilityLayer(peerId, scalabilityLevel, isTeacher) {
    console.log('Lesson setPreferredScalabilityLayer', { peerId, scalabilityLevel, isTeacher });
    const { roomId } = this.roomInfo;
    const remoteMedias = this.mediasoup.getAllRemoteMedias();
    let remoteMedia;
    if (isTeacher) {
      remoteMedia = remoteMedias.find(teacherMedia => teacherMedia.isTeacherPeerType === true);
    } else {
      remoteMedia = remoteMedias.find(remoteMediaItem => remoteMediaItem.peerId === peerId);
    }
    console.log('remoteMedia', remoteMedia);
    if (remoteMedia) {
      const isVideo = remoteMedia.media[mediaTypes.videoType];
      const isScreenShare = remoteMedia.media[mediaTypes.screenType];
      let consumerId;
      let layers;
      let spatialLayer;
      let temporalLayer;
      if (isVideo) {
        consumerId = remoteMedia.media[mediaTypes.videoType]?.consumer?.id;
        layers = remoteMedia.media[mediaTypes.videoType]?.scalabilityLayers;
      }
      if (isScreenShare) {
        consumerId = remoteMedia.media[mediaTypes.screenType]?.consumer?.id;
        layers = remoteMedia.media[mediaTypes.screenType]?.scalabilityLayers;
      }
      if (layers) {
        const parsedLayers = getLayersByLevel(scalabilityLevel, layers);
        spatialLayer = parsedLayers.spatialLayer;
        temporalLayer = parsedLayers.temporalLayer;
      }
      try {
        this.queue.pushTask(peerId, () => {
          this.mediasoup.setPreferredScalabilityLayer(
            peerId,
            consumerId,
            { spatialLayer, temporalLayer },
            isTeacher,
          );
          this.queue.resumeTaskExecuting(peerId);
        });
        if (consumerId) {
          await this.socket.request(this.videoConferenceActions.setLayers, {
            roomId,
            consumerId,
            spatialLayer: String(spatialLayer),
            temporalLayer: String(temporalLayer),
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  async getChatMessages(page) {
    await this.socket.request(this.chatActions.getMessagesChat, { page });
  }

  async readMessageChat(id) {
    await this.socket.request(this.chatActions.readMessageChat, {
      message: { id },
    });
  }

  async sendChatMessage(message, attachments = []) {
    await this.socket.request(this.chatActions.sendMessageChat, {
      lesson_hash: this.lessonHash,
      body: message,
      attachments,
    });
  }

  async deleteChatMessage(id) {
    await this.socket.request(this.chatActions.deleteMessageChat, {
      lesson_hash: this.lessonHash,
      message: { id },
    });
  }

  async startScreenShare() {
    console.log('Lesson startScreenShare');
    try {
      const { isVideo } = await this.mediasoup.produce(mediaTypes.screenType);
      if (isVideo) {
        // await this.pauseLocalVideo();
        console.log('isVideo');
      }
    } catch (e) {
      if (!(e instanceof MediaError)) {
        toast.error(e.message);
      }
    }
  }

  async stopScreenShare() {
    console.log('Lesson stopScreenShare');
    // if (this.mediasoup.isResumeVideoAfterCloseScreenShare) {
    //   await this.resumeLocalVideo();
    //   return;
    // }
    const { roomId } = this.roomInfo;
    const { producerId } = this.mediasoup.mediaStore.localMedia[mediaTypes.screenType];
    await this.socket.request(this.videoConferenceActions.stopShareScreen, {
      roomId,
      producerId,
    });
    this.mediasoup.closeProducer(producerId);
  }

  async showThemeButtons(peerId) {
    if (peerId) {
      await this.socket.request(this.videoConferenceTeacherAction.showThemeButtonsTo, {
        peer_id: peerId,
      });
    } else {
      await this.socket.request(this.videoConferenceTeacherAction.showThemeButtons);
    }
  }

  async hideThemeButtons(peerId) {
    if (peerId) {
      await this.socket.request(this.videoConferenceTeacherAction.hideThemeButtonsTo, {
        peer_id: peerId,
      });
    } else {
      await this.socket.request(this.videoConferenceTeacherAction.hideThemeButtons);
    }
  }

  async firstThemeButtonReaction() {
    await this.socket.request(this.videoConferenceTeacherAction.firstThemeButtonReaction);
  }

  async secondThemeButtonReaction() {
    await this.socket.request(this.videoConferenceTeacherAction.secondThemeButtonReaction);
  }

  async studentToiletNeeded() {
    await this.socket.request(this.videoConferenceTeacherAction.toiletNeed);
  }

  async studentHandUppend() {
    await this.socket.request(this.videoConferenceTeacherAction.handUp);
  }

  async showYesNoButtons(peerId) {
    if (peerId) {
      await this.socket.request(this.videoConferenceTeacherAction.showYesNoButtonsTo, {
        peer_id: peerId,
      });
    } else {
      await this.socket.request(this.videoConferenceTeacherAction.showYesNoButtons);
    }
  }

  async yesButtonReaction() {
    await this.socket.request(this.videoConferenceTeacherAction.yesButtonReaction);
  }

  async noButtonReaction() {
    await this.socket.request(this.videoConferenceTeacherAction.noButtonReaction);
  }

  async sendEmojiNotification(emojiType, peerId) {
    const payload = { emoji_type: emojiType };
    let action = this.videoConferenceTeacherAction.emojiNotification;
    if (peerId) {
      action = this.videoConferenceTeacherAction.emojiNotificationTo;
      payload.peer_id = peerId;
    }
    await this.socket.request(action, payload);
  }

  async sendStopNotification(peerId) {
    let payload = null;
    let action = this.videoConferenceTeacherAction.stopNotification;
    if (peerId) {
      action = this.videoConferenceTeacherAction.stopNotificationTo;
      payload = { peer_id: peerId };
    }
    await this.socket.request(action, payload);
  }

  async sendAttentionNotification(type, peerId) {
    let action = this.videoConferenceTeacherAction.attentionNotification;
    const payload = { attention_type: type };
    if (peerId) {
      action = this.videoConferenceTeacherAction.attentionNotificationTo;
      payload.peer_id = peerId;
    }
    await this.socket.request(action, payload);
  }

  async sendCallNotification() {
    await this.socket.request(this.videoConferenceTeacherAction.callNotification);
  }

  async sendStartMedia(type, peerId) {
    let action = this.videoConferenceTeacherAction.startStreamByCameraTo;
    const { roomId } = this.roomInfo;
    if (type === mediaTypes.audioType) {
      action = this.videoConferenceTeacherAction.startStreamByMicrophoneTo;
    }
    this.socket.request(action, { peer_id: peerId, room_id: roomId });
  }

  async sendStopMedia(type, peerId) {
    let action = this.videoConferenceTeacherAction.stopStreamByCameraTo;
    const { roomId } = this.roomInfo;
    if (type === mediaTypes.audioType) {
      action = this.videoConferenceTeacherAction.stopStreamByMicrophoneTo;
    }
    this.socket.request(action, { peer_id: peerId, room_id: roomId });
  }

  _init() {
    const [educationStakeholder, lessonHash] = getSearchParams([
      'educationStakeholder',
      'lessonHash',
    ]);
    this.educationStakeholder =
      educationStakeholder ?? localStorage.lessonAuthorizationData?.educationStakeholder;
    this.lessonHash = lessonHash ?? localStorage.lessonAuthorizationData?.lessonHash;
    this._setRoleInLesson(this.educationStakeholder);
    this._defineStores();
    this.socket = new Socket(
      this.educationStakeholder,
      this.lessonHash,
      this.modalWindowsStore,
      this.exitFromLesson,
      this.oppositeEventNames,
    );
    this.mediasoup = new Mediasoup(this.socket.request, this.videoConferenceActions);
    this.queue = new Queue();
    this.messageAdapter = new MessageAdapter();
  }

  _defineStores() {
    this.modalWindowsStore = store.getStore('modalWindowsStore');
    this.buttonsStore = store.getStore('buttonsStore');
    this.chatStore = store.getStore('chatStore');
    this.reactionStore = store.getStore('reactionStore');
  }

  _clearData() {
    this.socket = null;
    this.mediasoup = null;
    this.modalWindowsStore = null;
    this.buttonsStore = null;
    this.reactionStore = null;
    this.roleInLesson = {
      value: '',
      isTeacher: null,
      isStudent: null,
    };
    this.lessonHash = '';
    this.educationStakeholder = '';
    this.roomInfo = null;
    this.peerInfo = null;
    this.isIndividualLesson = null;
    this.teacherPeerId = '';
    this.isLessonInformationExist = false;
    this.isLessonOnPause = false;
    this.messageAdapter = '';
    lesson = null;
    this.isReadyToHandleEvents = false;
    this.isRelevant = false;
  }

  _setRoleInLesson(value) {
    console.log('Lesson _setRoleInLesson', value);
    if (
      value !== availableLessonRoles.teacher &&
      value !== availableLessonRoles.student &&
      value !== null
    ) {
      const error = new Error(
        `Role must be one of (${availableLessonRoles.teacher} || ${availableLessonRoles.student} || null)`,
      );
      error.humanReadableMessage =
        'Роль на уроке указана неверно. Возможно, ссылка была скопирована неправильно и/или изменена';
      error.code = 403;
      throw error;
    }

    this.roleInLesson.value = value || '';
    if (value) {
      this.roleInLesson.isStudent = value === availableLessonRoles.student;
      this.roleInLesson.isTeacher = value === availableLessonRoles.teacher;
    } else {
      this.roleInLesson.isStudent = value === null;
      this.roleInLesson.isTeacher = value === null;
    }
  }

  async _joinLesson(isReconnected = false) {
    const { data } = await this.socket.request(this.actions.joinLesson);
    if (!this.isRelevant) {
      return;
    }
    const { roomId } = data.payload.lesson.room;

    await this.socket.request(this.actions.getRoomInfo, { roomId });
    const { search, pathname } = window.location;

    const searchParams = getSearchParams(['educationStakeholder', 'lessonHash'], search);

    if (searchParams[0] !== this.educationStakeholder && searchParams[1] !== this.lessonHash) {
      const params = {
        educationStakeholder: this.educationStakeholder,
        lessonHash: this.lessonHash,
      };
      const queryString = objectToSearchParams(params);
      history.replace({ pathname, search: queryString });
    }

    if (this.isLessonOnPause) {
      history.push(navigation.lessonBreak.pathWithSearch);
    } else {
      history.push(navigation.videoConference.pathWithSearch);
      await this.startLocalMedia(true, isReconnected);
    }
    this.isReadyToHandleEvents = true;
    await this.socket.rawEvents.execute();
  }

  _removePeersInfo() {
    if (this?.peerInfo) {
      this.peerInfo = null;
    }
    this.mediasoup?.removeAllConsumers();
  }
}

export { lesson, Lesson };
