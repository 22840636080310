import { mediaTypes } from '../../../constants/mediaTypes';
import userJoinToLessonInitLessonAdapter from '../socketEvent/adapters/userJoinToLessonInitLessonAdapter';
import userStartedStreamInitLessonAdapter from '../socketEvent/adapters/userStartedStreamInitLessonAdapter';

async function joinLesson(lesson) {
  const { room } = lesson;
  const { roomId, rtpCapabilities, peer, otherPeers } = room;
  this.roomInfo = { roomId, rtpCapabilities };
  this.peerInfo = peer;

  if (!this.isRelevant) {
    return;
  }

  await this.mediasoup.init(roomId, rtpCapabilities, peer.transports);
  if (!this.isIndividualLesson && this.roleInLesson.isStudent) {
    this.mediasoup.setNewMediaBlockedStreams({ [mediaTypes.videoType]: true }, true);
  }

  if (peer.isTeacher) {
    this.teacherPeerId = peer.peerId;
  }

  if (!this.isLessonInformationExist) {
    await this.socket.request(this.actions.getLessonInfo);
  }

  for (const otherPeer of otherPeers) {
    const { peerId, producers } = otherPeer;

    for (const producer of producers) {
      const event = {
        eventName: this.videoConferenceEvents.userStartedStream,
        pairID: producer.streamType,
        eventData: {
          action: this.videoConferenceEvents.userStartedStream,
          payload: userStartedStreamInitLessonAdapter({ ...otherPeer, producer }),
        },
        peerID: peerId,
      };
      this.socket.rawEvents.push(event, true);
    }
  }

  for (const otherPeer of otherPeers) {
    const { peerId } = otherPeer;
    const event = {
      eventName: this.events.userJoinedToLesson,
      eventData: {
        action: this.events.userJoinedToLesson,
        payload: userJoinToLessonInitLessonAdapter(otherPeer),
      },
      peerID: peerId,
    };
    this.socket.rawEvents.push(event, true);
  }
}

export default joinLesson;
