import { toast } from 'react-toastify';
import history from '../../../utils/history/history';
import navigation from '../../../constants/navigation';

async function lessonIsStarted() {
  await this.socket
    .request(this.actions.joinLesson)
    .then(() => {
      history.push(navigation.videoConference.pathWithSearch);
      this.isReadyToHandleEvents = true;
      return this.socket.rawEvents.execute();
    })
    .then(() => {
      this.startLocalMedia();
    })
    .catch(() => {
      toast.error('Не удалось подключиться к уроку');
    });
}

export default lessonIsStarted;
