import checkIsPeerIdRemotePeerId from '../../../../utils/checkIsPeerIdRemotePeerId';
import mediaTypeAdapter from '../../../../utils/mediaTypeAdapter';
import userStoppedStreamEventAdapter from '../adapters/userStoppedStreamEventAdapter';

function userStoppedStream(action, payload) {
  let adaptedPayload = { ...payload };
  if (!adaptedPayload.adapted) {
    adaptedPayload = userStoppedStreamEventAdapter(payload);
  }

  const { peerId, producer } = adaptedPayload;

  if (!this.isReadyToHandleEvents) {
    const event = {
      eventName: action,
      pairID: producer.streamType,
      eventData: { action, payload },
      peerID: peerId,
    };
    this.socket.rawEvents.push(event);
    return;
  }

  const isRemotePeerId = checkIsPeerIdRemotePeerId(this.peerInfo, peerId);

  if (isRemotePeerId) {
    const isTeacher = this.teacherPeerId === peerId;
    const { producerId, streamType } = producer;
    const mediaType = mediaTypeAdapter(streamType);
    this.queue.pushTask(peerId, () => {
      this.mediasoup.closeConsumer(peerId, producerId, mediaType, isTeacher);
      this.queue.resumeTaskExecuting(peerId);
    });
  }
}

export default userStoppedStream;
