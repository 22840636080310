import apiURLs from '../../constants/apiURLs';

class SubjectsEndpoint {
  constructor(axios, setBearer, removeBearer) {
    this.axios = axios;
    this.setBearer = setBearer;
    this.removeBearer = removeBearer;
  }

  getAllSubjects = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getSubjects(),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getCurrentSubject = async id => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getCurrentSubject(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}
export default SubjectsEndpoint;
