import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

import App from './App';
import MainPreloader from './components/MainPreloader/MainPreloader';
import { initSentry, ErrorBoundary } from './Sentry';
import { createStore, store } from './store';
import './api';
import defaultRedirection from './utils/history/defaultRedirect';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/main.scss';
import MainPreloaderWithUseStore from './components/MainPreloader/MainPreloaderWithUseStore';
import ReloadApp from './components/Modal/Reload/ReloadApp/ReloadApp';
import ReloadAppWithUseStore from './components/Modal/Reload/ReloadApp/ReloadAppWithUseStore';
import ReloadSocketConnection from './components/Modal/Reload/ReloadSocketConnection';
import { initAudios } from './constants/audios/audios';

initSentry();
(async () => {
  ReactDOM.render(<MainPreloader />, document.getElementById('root'));
  initAudios();
  const StoreProvider = await createStore({});
  try {
    await defaultRedirection();
  } catch (e) {
    const { humanReadableMessage, message, code } = e;
    const globalPropsStore = store.getStore('globalPropsStore');
    globalPropsStore.setGlobalError(code, humanReadableMessage ?? message);
  }

  ReactDOM.render(
    <StoreProvider>
      <React.StrictMode>
        <ToastContainer
          position="top-center"
          pauseOnHover={false}
          hideProgressBar
          autoClose={5000}
          style={{ zIndex: 1999 }}
          theme="dark"
        />
        <MainPreloaderWithUseStore />
        <ReloadAppWithUseStore />
        <ReloadSocketConnection />
        <ErrorBoundary fallback={<ReloadApp />}>
          <React.Suspense fallback={<MainPreloader />}>
            <App />
          </React.Suspense>
        </ErrorBoundary>
      </React.StrictMode>
    </StoreProvider>,
    document.getElementById('root'),
  );
})();
