import Bowser from 'bowser';
import navigation from '../../constants/navigation';

const isPathVisitedByAllowedBrowser = (location, options = {}) => {
  const { lessonWaiting, lessonBreak, lessonEnd, videoConference } = navigation;
  const {
    allowedBrowsers = ['Chrome'],
    forbiddenPaths = [lessonWaiting.path, lessonBreak.path, lessonEnd.path, videoConference.path],
  } = options;
  const { pathname } = location ?? {};

  if (!(location && typeof location === 'object' && 'pathname' in location)) {
    throw new Error('Location should be an object with pathname object property');
  }

  if (!Array.isArray(allowedBrowsers)) {
    throw new Error('AllowedBrowsers should be an array');
  }

  if (!Array.isArray(forbiddenPaths)) {
    throw new Error('ForbiddenPaths should be an array');
  }

  const isForbiddenPath = forbiddenPaths.some(path => pathname === path);

  if (isForbiddenPath) {
    const { userAgent } = navigator;
    const browser = Bowser.getParser(userAgent);
    const browserName = browser.getBrowserName(true);
    return allowedBrowsers.some(allowedBrowser => {
      const allowedBrowserLowerCase = allowedBrowser.toLowerCase();
      return browserName === allowedBrowserLowerCase;
    });
  }

  return true;
};

export default isPathVisitedByAllowedBrowser;
