class MessageAdapter {
  constructor(message) {
    this._adaptedMessage = null;
    if (message) {
      this.setMessage(message);
    }
  }

  setMessage({ chatObj: { message, id } }, withoutSender = false) {
    this._adaptedMessage = this._setMessage(message, id);
    if (!withoutSender) {
      this._adaptedMessage.sender = this._setSender(message);
      this._attachAttachmentsToMessage();
    }

    return this.adaptedMessage;
  }

  clearMessage() {
    this._adaptedMessage = null;
  }

  _setNewMessage = order => Number(order);

  get adaptedMessage() {
    return this._adaptedMessage;
  }

  set adaptedMessage(message) {
    this.setMessage(message);
  }

  _setMessage = (message, chatId) => {
    if (!message || typeof message !== 'object') {
      throw new Error('Message must be an object');
    }
    const { body, attachments = [], created_at: createdAt } = message;
    const { id, order, sender } = message;
    const { user_id } = sender;
    
    return { id, body, order, attachments, createdAt, chatId, sender, user_id };
  };

  _setSender = message => {
    const { sender } = message;
    if (!sender || typeof sender !== 'object') {
      throw new Error('Message sender must be an object');
    }

    const { full_name: fullName, is_teacher: isTeacher } = sender;
    return { fullName, isTeacher };
  };

  _attachAttachmentsToMessage() {
    if (!this._adaptedMessage) {
      throw new Error('Message is empty');
    }
    if (!this._adaptedMessage.sender) {
      throw new Error('There is no sender in message');
    }

    const { attachments: messageAttachments } = this._adaptedMessage;
    const { attachments: senderAttachments } = this._adaptedMessage.sender;

    if (senderAttachments && !messageAttachments) {
      this._adaptedMessage.attachments = senderAttachments;
    }

    delete this._adaptedMessage.sender.attachments;
  }
}

export default MessageAdapter;
