import singletonInstance from './singletonInstance';

class LocalStorage {
  constructor() {
    this._accessToken = null;
    this._role = null;
    this._userName = null;
    this._userId = null;
    this._avatar = null;
    this._lessonAuthorizationData = null;
    this._timezone = null;
    this._adultLocation = null;
    this.fields = {
      accessToken: 'accessToken',
      role: 'role',
      userName: 'userName',
      userId: 'userId',
      lessonAuthorizationData: 'lessonAuthorizationData',
      secondName: 'secondName',
      avatar: 'avatar',
      timezone: 'timezone',
      adultLocation: 'adultLocation',
    };
    return singletonInstance.call(this);
  }

  get accessToken() {
    return this._getter('accessToken');
  }

  set accessToken(value) {
    this._setter('accessToken', value);
  }

  get role() {
    const roleValue = this._getter('role');
    if (Array.isArray(roleValue)) {
      return roleValue.length > 1 ? roleValue : roleValue[0];
    }
    return roleValue;
  }

  set role(value) {
    this._setter('role', value);
  }

  get userName() {
    return this._getter('userName');
  }

  set userName(value) {
    this._setter('userName', value);
  }

  get secondName() {
    return this._getter('secondName');
  }

  set secondName(value) {
    this._setter('secondName', value);
  }

  get avatar() {
    return this._getter('avatar');
  }

  set avatar(value) {
    this._setter('avatar', value);
  }

  get userId() {
    return this._getter('userId');
  }

  set userId(value) {
    this._setter('userId', value);
  }

  get lessonAuthorizationData() {
    return this._getter('lessonAuthorizationData');
  }

  set lessonAuthorizationData(value) {
    this._setter('lessonAuthorizationData', value);
  }

  get timezone() {
    return this._getter('timezone');
  }

  set timezone(value) {
    this._setter('timezone', value);
  }

  get adultLocation() {
    return this._getter('adultLocation');
  }

  set adultLocation(value) {
    this._setter('adultLocation', value);
  }

  delete(keyName) {
    const privateKeyName = this._getPrivateKeyName(keyName);
    if (this[privateKeyName] !== undefined) {
      this[privateKeyName] = null;
      localStorage.removeItem(keyName);
    }
  }

  deleteAll() {
    Object.keys(this.fields).forEach(keyName => {
      this.delete(keyName);
    });
  }

  _getPrivateKeyName = keyName => `_${keyName}`;

  _getValue(keyName) {
    const privateKey = this._getPrivateKeyName(keyName);
    return this[privateKey];
  }

  _getter(keyName) {
    const value = this._getValue(keyName);
    if (value) {
      return value;
    }
    const localStorageItem = localStorage.getItem(keyName);
    try {
      return JSON.parse(localStorageItem);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`localStorage getter of ${keyName}`, e);
      return localStorageItem;
    }
  }

  _setter(keyName, value) {
    try {
      localStorage.setItem(keyName, JSON.stringify(value));
      this[`_${keyName}`] = value;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`localStorage setter of ${keyName}. Trying to set value: ${value}`, e);
      throw e;
    }
  }
}

export default new LocalStorage();
