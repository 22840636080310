function userDeletedMessage(payload) {
  console.log('event deletedMessageChat', payload);
  const { id } = payload.lesson.chat.message;
  const updatedMessages = this.chatStore.messages.filter(message => message.id !== id);
  const unreadAmount = +localStorage.getItem('amount_unread') - 1;
  const unread_messages = JSON.parse(localStorage.getItem('unread_messages'));
  if (+localStorage.getItem('amount_unread') > 0 && unread_messages.includes(id)) {
    localStorage.setItem('amount_unread', unreadAmount);
    this.chatStore.updateCounter(unreadAmount);
  }
  this.chatStore.setMessages(updatedMessages, true);
}

export default userDeletedMessage;
