import moment from 'moment';
import apiURLs from '../../constants/apiURLs';

class EducationEventsEndpoint {
  constructor(axios, setBearer, removeBearer) {
    this.axios = axios;
    this.setBearer = setBearer;
    this.removeBearer = removeBearer;
  }

  getTeacherEducationEvents = async (from, to, students, statuses) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getTeacherEducationEvents,
        params: {
          date_from: moment(from).format('YYYY-MM-DD'),
          date_to: moment(to).format('YYYY-MM-DD'),
          students: students.map(item => item.user.id),
          statuses: statuses.map(item => item.original_name),
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getCurrentTeacherEducationEvents = async (id, from, to, students, statuses) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getCurrentTeacherEducationEvents(id),
        params: {
          date_from: moment(from).format('YYYY-MM-DD'),
          date_to: moment(to).format('YYYY-MM-DD'),
          students: students.map(item => item.user.id),
          statuses: statuses.map(item => item.original_name),
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getCurrentStudentEducationEvents = async (id, from, to, teachers, statuses) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getCurrentStudentEducationEvents(id),
        params: {
          date_from: moment(from).format('YYYY-MM-DD'),
          date_to: moment(to).format('YYYY-MM-DD'),
          teachers: teachers.map(item => item.user.id),
          statuses: statuses.map(item => item.original_name),
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getStudentEducationEvents = async (from, to) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getStudentEducationEvents,
        params: {
          date_from: moment(from).format('YYYY-MM-DD'),
          date_to: moment(to).format('YYYY-MM-DD'),
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getEducationEventStatuses = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getEducationEventStatuses,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getEventList = async () => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getEventList,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getEventTimeList = async params => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getEventTimeList,
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  createEducationEvent = async data => {
    const filterData = { ...data };
    if (filterData.subject_id === '') {
      delete filterData.subject_id;
    }

    if (filterData.class_id === '') {
      delete filterData.class_id;
    }
    if (
      (filterData.type_id !== 1 || filterData.type_id !== 2 || filterData.type_id !== 6) &&
      (filterData.name === '' || filterData.name === null)
    ) {
      delete filterData.name;
    }

    if (filterData.description === null) {
      delete filterData.description;
    }
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.createEducationEvent,
        data: filterData,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  editEducationEvent = async (id, data) => {
    const filterData = { ...data };
    if (filterData.subject_id === '') {
      delete filterData.subject_id;
    }

    if (filterData.class_id === '') {
      delete filterData.class_id;
    }
    if (
      (filterData.type_id !== 1 || filterData.type_id !== 2 || filterData.type_id !== 6) &&
      (filterData.name === '' || filterData.name === null)
    ) {
      delete filterData.name;
    }
    if (filterData.description === null) {
      delete filterData.description;
    }
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.editEducationEvent(id),
        data: filterData,
        params: {
          _method: 'PUT',
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  changeEventStatus = async (id, data) => {
    const filterData = { ...data };
    if (filterData.start_at === '') {
      delete filterData.start_at;
    }

    if (filterData.start_time === '') {
      delete filterData.start_time;
    }
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.changeEventStatus(id),
        data: filterData,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  deleteEvent = async id => {
    try {
      const response = await this.axios({
        method: 'DELETE',
        url: apiURLs.deleteEvent(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  downloadTeacherEvents = async params => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.downloadTeacherEvents,
        responseType: 'blob',
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  downloadStudentEvents = async params => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.downloadStudentEvents,
        responseType: 'blob',
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  downloadCurrentStudentEvents = async (params, id) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.downloadCurrentStudentEvents(id),
        responseType: 'blob',
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  downloadCurrentTeacherEvents = async (params, id) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.downloadCurrentTeacherEvents(id),
        responseType: 'blob',
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}

export default EducationEventsEndpoint;
