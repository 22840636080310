import checkIsPeerIdRemotePeerId from '../../../utils/checkIsPeerIdRemotePeerId';
import userJoinToLessonEventAdapter from './adapters/userJoinToLessonEventAdapter';

function userJoinedToLesson(action, payload) {
  let adaptedPayload = { ...payload };
  if (!adaptedPayload.adapted) {
    adaptedPayload = userJoinToLessonEventAdapter(payload);
  }

  const { peerId, name, isTeacher } = adaptedPayload;

  if (!this.isReadyToHandleEvents) {
    const event = {
      eventName: action,
      eventData: { action, payload: adaptedPayload },
      peerID: peerId,
    };
    this.socket.rawEvents.push(event);
    return;
  }
  const isRemotePeerId = checkIsPeerIdRemotePeerId(this.peerInfo, peerId);

  if (isRemotePeerId) {
    if (isTeacher) {
      this.teacherPeerId = peerId;
    }
    this.queue.pushTask(peerId, () => {
      this.mediasoup.consumeEmpty(peerId, name, isTeacher, this.isIndividualLesson);
      this.queue.resumeTaskExecuting(peerId);
    });

    if (this.mediasoup.isInitialized) {
      this.queue.startTask(peerId);
    }
  }
}

export default userJoinedToLesson;
