import { toast } from 'react-toastify';

function showYesNoButtons() {
  const { isTeacher } = this.roleInLesson;
  const { displayYesNoButtons } = this.buttonsStore;
  if (!isTeacher) {
    displayYesNoButtons(true);
  } else {
    toast.info('Вы отправили кнопки «да/нет»');
  }
}

export default showYesNoButtons;
