import MediaError from './MediaError';
import { mediaTypes } from '../../constants/mediaTypes';

class Media {
  constructor() {
    this.videoResolutions = {
      qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
      vga: { width: { ideal: 640 }, height: { ideal: 480 } },
      hd: { width: { ideal: 1280 }, height: { ideal: 720 } },
    };
    this.constraints = {
      audio: true,
      video: {
        facingMode: 'user',
        ...this.videoResolutions.hd,
      },
    };
    this.videoEncodings = [
      { scaleResolutionDownBy: 4, maxBitrate: 500000 },
      { scaleResolutionDownBy: 2, maxBitrate: 1000000 },
      { scaleResolutionDownBy: 1, maxBitrate: 4500000 },
    ];
    this.screenshareEncodings = [
      { dtx: true, maxBitrate: 1500000 },
      { dtx: true, maxBitrate: 6000000 },
    ];
    this.videoCodecOptions = {
      videoGoogleStartBitrate: 1000,
    };
    this._streams = {
      [mediaTypes.audioType]: null,
      [mediaTypes.videoType]: null,
      [mediaTypes.screenType]: null,
    };
    this.audioInputDevides = new Map();
    this.audioOutputDevices = new Map();
    this.videoInputDevices = new Map();
    this._areDevidesEnumerated = false;
    this.deviceKinds = {
      audioInput: 'audioinput',
      audioOutput: 'audiooutput',
      videoInput: 'videoinput',
    };
  }

  async getLocalMedia(mediaType, deviceId, isKeepingPreviousState) {
    if (isKeepingPreviousState) {
      return this._streams[mediaType];
    }
    try {
      switch (mediaType) {
        case mediaTypes.videoType:
        case mediaTypes.audioType: {
          const constraints = {
            audio: mediaType === mediaTypes.audioType && { ...this.constraints.audio, deviceId },
            video: mediaType === mediaTypes.videoType && { ...this.constraints.video, deviceId },
          };
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          this._streams[mediaType] = stream;
          return stream;
        }
        case mediaTypes.screenType: {
          const stream = await navigator.mediaDevices.getDisplayMedia();
          this._streams[mediaType] = stream;
          return stream;
        }
        default:
          throw new MediaError(null, mediaType);
      }
    } catch (e) {
      throw new MediaError(e.name, mediaType);
    }
  }

  removeLocalMedia(mediaType) {
    if (
      mediaType === mediaTypes.audioType ||
      mediaType === mediaTypes.videoType ||
      mediaType === mediaTypes.screenType
    ) {
      this._streams[mediaType] = null;
    }
  }

  async _enumerateDevices() {
    await navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        devices.forEach(device => {
          const { deviceId, kind } = device;
          switch (kind) {
            case this.deviceKinds.audioInput:
              this.audioInputDevides.set(deviceId, device);
              break;
            case this.deviceKinds.audioOutput:
              this.audioOutputDevices.set(deviceId, device);
              break;
            case this.deviceKinds.videoInput:
              this.videoInputDevices.set(deviceId, device);
              break;
            // no default
          }
        });
        this._areDevidesEnumerated = true;
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error('_enumerateDevices', { e });
      });
  }
}

export default Media;
