import { makeAutoObservable } from 'mobx';
import getLastMapEntry from '../utils/getLastMapEntry';

class ReactionStore {
  constructor() {
    this.allReaction = new Map();
    makeAutoObservable(this);
  }

  removeReaction = id => {
    this.allReaction.delete(id);
  };

  setReaction = value => {
    let id;
    if (!this.allReaction.size) {
      id = 1;
    } else {
      id = getLastMapEntry(this.allReaction)[0] + 1;
    }
    this.allReaction.set(id, value);
    const timer = setTimeout(() => {
      this.removeReaction(id);
      clearTimeout(timer);
    }, 10000);
  };
}

export default ReactionStore;
