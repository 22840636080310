const mediaTypes = {
  audioType: 'audio',
  videoType: 'video',
  screenType: 'screen',
};

function getMediaTypeByKind(kind) {
  switch (kind) {
    case 'audio':
      return mediaTypes.audioType;
    case 'video':
      return mediaTypes.videoType;
    default:
      throw new Error(`Kind must be one of (audio || video), but kind is ${kind}`);
  }
}

export { mediaTypes, getMediaTypeByKind };
