import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../store';
import ReloadApp from './ReloadApp';

function ReloadAppWithUseStore() {
  const {
    modalWindowsStore: { reloadApp },
  } = useStore();

  if (!reloadApp) {
    return null;
  }

  return <ReloadApp />;
}

export default observer(ReloadAppWithUseStore);
