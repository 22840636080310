import history from '../../../utils/history/history';
import navigation from '../../../constants/navigation';

function lessonBreakStarted() {
  console.log('lessonBreakStarted');
  this.isLessonOnPause = true;
  if (!this.isIndividualLesson) {
    this.isAllClassMode = !this.roleInLesson.isStudent;
  }
  history.push(navigation.lessonBreak.pathWithSearch);
  this.mediasoup.resetForcedBlockedMediaStreams();
  this.mediasoup.closeAllProducers();
  this.mediasoup.closeAllConsumers(!this.isIndividualLesson);
}

export default lessonBreakStarted;
