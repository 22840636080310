import * as mediasoupClient from 'mediasoup-client';
import { store } from '../../store';
import MediasoupCanProduceError from './MediasoupError';
import { mediaTypes } from '../../constants/mediaTypes';
import Media from '../media/Media';
import { getLayersByLevel } from '../../constants/scalabilityLayers';

class Mediasoup {
  constructor(socketRequest, videoConferenceActions) {
    this.mediaStore = null;
    this.media = new Media();
    this.socketRequest = socketRequest;
    this.videoConferenceActions = videoConferenceActions;
    this.device = null;
    this.producerTransport = null;
    this.consumerTransport = null;
    this.isResumeVideoAfterCloseScreenShare = false;
    this.isInitialized = false;
    this.transportActions = {
      connectTransport: 'connectTransport',
    };
    this.transportEvents = {
      connect: 'connect',
      produce: 'produce',
      connectionstatechange: 'connectionstatechange',
    };
  }

  async init(roomId, rtpCapabilities, transports) {
    this._defineStore();
    await this._loadDevice(rtpCapabilities);
    this._initTransports(roomId, transports);
    this.isInitialized = true;
  }

  stopMediaTransfer() {
    if (this.producerTransport && !this.producerTransport.closed) {
      this.producerTransport.close();
    }
    if (this.consumerTransport && !this.consumerTransport.closed) {
      this.consumerTransport.close();
    }

    this.closeAllProducers();
    this.removeAllConsumers();
  }

  async produce(mediaType, isKeepingPreviousState = false) {
    const stream = await this.canBeProduced(mediaType, isKeepingPreviousState);
    const { isScreenShare, isVideo, isAudio } = this.mediaStore.localMedia;
    if (stream) {
      await this.produceStream(stream, mediaType);
      if (mediaType === mediaTypes.screenType && isVideo) {
        this.isResumeVideoAfterCloseScreenShare = true;
      }
      if (mediaType === mediaTypes.videoType) {
        this.isResumeVideoAfterCloseScreenShare = false;
      }
    }
    return { isScreenShare, isVideo, isAudio };
  }

  async produceStream(stream, mediaType) {
    const isMediaTrackAlreadyExist = this.mediaStore.localMedia.media?.[mediaType];

    if (stream && !isMediaTrackAlreadyExist) {
      const params = {};
      [params.track] = stream.getTracks();
      params.appData = { mediaType };
      if (mediaType === mediaTypes.videoType || mediaType === mediaTypes.screenType) {
        console.log('CODECS', this.device.rtpCapabilities.codecs);
        params.encodings =
          mediaType === mediaTypes.videoType
            ? this.media.videoEncodings
            : this.media.screenshareEncodings;
        params.codecOptions = this.media.videoCodecOptions;
      }
      const producer = await this.producerTransport.produce(params);
      this.mediaStore.setLocalMediaTrack(producer, mediaType);
      this._onProducerEvents(producer);
    }
  }

  async canBeProduced(mediaType, isKeepingPreviousState = false) {
    const stream = await this.media.getLocalMedia(mediaType, null, isKeepingPreviousState);
    const canBeProduced = this.device.canProduce(
      mediaType === mediaTypes.screenType ? mediaTypes.videoType : mediaTypes.audioType,
    );
    if (!canBeProduced) {
      throw new MediasoupCanProduceError(mediaType);
    }
    return stream;
  }

  closeProducer(producerId, mediaType) {
    if (mediaType && !producerId) {
      const { producerId: localMediaProducerId } = this.mediaStore.localMedia[mediaType];
      this.mediaStore.removeLocalMediaTrack(localMediaProducerId);
      this.media.removeLocalMedia(mediaType);
    } else if (!mediaType && producerId) {
      const mediaTypeByProducerId = Object.values(mediaTypes).find(
        mediaTypeItem => this.mediaStore.localMedia[mediaTypeItem]?.producerId === producerId,
      );
      if (mediaTypeByProducerId) {
        this.media.removeLocalMedia(mediaTypeByProducerId);
      }
      this.mediaStore.removeLocalMediaTrack(producerId);
    }
  }

  closeAllProducers() {
    const videoProducerId = this.mediaStore?.localMedia?.[mediaTypes.videoType]?.producerId;
    const audioProducerId = this.mediaStore?.localMedia?.[mediaTypes.audioType]?.producerId;
    const screenShareProducerId = this.mediaStore?.localMedia?.[mediaTypes.screenType]?.producerId;

    if (videoProducerId) {
      this.closeProducer(videoProducerId);
    }
    if (audioProducerId) {
      this.closeProducer(audioProducerId);
    }
    if (screenShareProducerId) {
      this.closeProducer(screenShareProducerId);
    }
  }

  async consume(roomId, producerInfo, peerId, isTeacher, scalabilityLevel) {
    console.log('Mediasoup consume', { producerInfo, peerId, isTeacher, scalabilityLevel });
    const { producerId, mediaType } = producerInfo;
    const { rtpCapabilities } = this.device;

    try {
      let remoteMedia;
      if (isTeacher) {
        remoteMedia = this.mediaStore.teacherMedia;
      } else {
        remoteMedia = this.mediaStore.remoteMedia.get(peerId);
      }
      if (remoteMedia.blockedStreams.streamsBlocking[mediaType]) {
        if (isTeacher) {
          this.mediaStore.setTeacherBlockedStreams(producerId, mediaType);
        } else {
          this.mediaStore.setRemoteMediaBlockedStreams(peerId, producerId, mediaType);
        }
      } else {
        const response = await this.socketRequest(this.videoConferenceActions.startConsumeStream, {
          rtpCapabilities,
          consumerTransportId: this.consumerTransport.id,
          producerId,
          roomId,
        });
        const { consumerId, kind, rtpParameters } = response.data.payload.consumer;
        const codecOptions = {};
        const consumer = await this.consumerTransport.consume({
          id: consumerId,
          producerId,
          kind,
          rtpParameters,
          codecOptions,
        });
        const { spatialLayers, temporalLayers } = mediasoupClient.parseScalabilityMode(
          consumer.rtpParameters.encodings[0].scalabilityMode,
        );
        if (isTeacher) {
          this.mediaStore.setTeacherMediaTrack(peerId, producerId, mediaType, consumer, {
            spatialLayers,
            temporalLayers,
          });
        } else {
          this.mediaStore.setRemoteMediaTrack(peerId, producerId, mediaType, consumer, {
            spatialLayers,
            temporalLayers,
          });
        }
        if (scalabilityLevel) {
          const layers = getLayersByLevel(scalabilityLevel, { spatialLayers, temporalLayers });
          const { spatialLayer, temporalLayer } = layers;
          this.setPreferredScalabilityLayer(peerId, consumerId, layers, isTeacher);
          await this.socketRequest(this.videoConferenceActions.setLayers, {
            roomId,
            consumerId,
            spatialLayer: String(spatialLayer),
            temporalLayer: String(temporalLayer),
          });
        }
        this._onConsumerEvents(consumer);
      }
    } catch (e) {
      console.error(e);
    }
  }

  consumeEmpty(peerId, name, isTeacher, isIndividualLesson) {
    if (isTeacher) {
      this.mediaStore.setTeacherMedia(peerId, name);
    } else {
      if (isIndividualLesson) {
        this.mediaStore.selectMedia(peerId);
      }
      this.mediaStore.setRemoteMedia(peerId, name);
    }
  }

  setCurrentScalabilityLayer(peerId, consumerId, layers, isTeacher) {
    console.log('Mediasoup setCurrentScalabilityLayer', { peerId, consumerId, layers, isTeacher });
    if (isTeacher) {
      this.mediaStore.setTeacherMediaCurrentScalabilityLayer(consumerId, layers);
    } else {
      this.mediaStore.setRemoteMediaCurrentScalabilityLayer(peerId, consumerId, layers);
    }
  }

  setPreferredScalabilityLayer(peerId, consumerId, layers, isTeacher) {
    console.log('Mediasoup setPreferredScalabilityLayer', {
      peerId,
      consumerId,
      layers,
      isTeacher,
    });
    if (isTeacher) {
      this.mediaStore.setTeacherMediaPreferredScalabilityLayer(consumerId, layers);
    } else {
      this.mediaStore.setRemoteMediaPreferredScalabilityLayer(peerId, consumerId, layers);
    }
  }

  closeConsumer(peerId, producerId, mediaType, isTeacher) {
    let remoteMedia;
    if (isTeacher) {
      remoteMedia = this.mediaStore.teacherMedia;
    } else {
      remoteMedia = this.mediaStore.remoteMedia.get(peerId);
    }
    if (remoteMedia) {
      if (remoteMedia.blockedStreams.streamsBlocking[mediaType]) {
        if (isTeacher) {
          this.mediaStore.removeTeacherBlockedStreams(mediaType);
        } else {
          this.mediaStore.removeRemoteMediaBlockedStreams(peerId, mediaType);
        }
      } else if (isTeacher) {
        this.mediaStore.removeTeacherMediaTrack(producerId);
      } else {
        this.mediaStore.removeRemoteMediaTrack(peerId, producerId);
      }
    }
  }

  closeAllConsumers(isRemoveSelected = false) {
    console.log('MediaSoup closeAllConsumers', { isRemoveSelected });
    const { teacherMedia } = this.mediaStore ?? {};
    const { remoteMedia } = this.mediaStore ?? {};
    if (teacherMedia) {
      this.mediaStore.removeTeacherMediaTracks();
      this.mediaStore.resetTeacherBlockedStreams();
    }
    if (remoteMedia) {
      for (const peerId of remoteMedia.keys()) {
        this.mediaStore.removeRemoteMediaTracks(peerId);
        this.mediaStore.resetRemoteMediaBlockedStreams(peerId);
      }
    }
    if (isRemoveSelected) {
      this.mediaStore.unselectMedia();
    }
  }

  removeConsumer(peerId, isTeacher) {
    if (isTeacher) {
      this.mediaStore.removeTeacherMedia();
    } else {
      this.mediaStore.removeRemoteMedia(peerId);
    }
  }

  removeAllConsumers() {
    const mediaStore = this.mediaStore ?? {};
    const { teacherMedia } = mediaStore ?? {};
    const { remoteMedia } = mediaStore ?? {};
    if (teacherMedia) {
      const { peerId } = teacherMedia;
      this.removeConsumer(peerId, true);
    }
    if (remoteMedia) {
      for (const peerId of remoteMedia.keys()) {
        this.removeConsumer(peerId, false);
      }
    }
  }

  getAllRemoteMedias() {
    return this.mediaStore.getAllRemoteMedias();
  }

  getRemoteMedias() {
    return this.mediaStore.getRemoteMedias();
  }

  getRemoteMedia(peerId) {
    return this.mediaStore.remoteMedia.get(peerId);
  }

  async unconsume(roomId, peerId, consumerId, producerId, isTeacher) {
    console.log('Mediasoup unconsume', { roomId, consumerId });
    try {
      await this.socketRequest(this.videoConferenceActions.stopConsumeStream, {
        roomId,
        consumerId,
      });
    } catch (e) {
      console.error(e);
    }
    if (isTeacher) {
      this.mediaStore.removeTeacherMediaTrack(producerId);
    } else {
      this.mediaStore.removeRemoteMediaTrack(peerId, producerId);
    }
  }

  async stopRemoteMediaLocally(roomId, peerId, mediaType, isTeacher) {
    console.log('Mediasoup stopRemoteMediaLocally', { peerId, mediaType, isTeacher });
    try {
      let remoteMedia;
      if (isTeacher) {
        remoteMedia = this.mediaStore.teacherMedia;
      } else {
        remoteMedia = this.mediaStore.remoteMedia.get(peerId);
      }
      if (remoteMedia && !remoteMedia.blockedStreams.currentBlockedStreams[mediaType]) {
        if (isTeacher) {
          this.mediaStore.createTeacherBlockedStreams(mediaType, true);
        } else {
          this.mediaStore.createRemoteMediaBlockedStreams(peerId, mediaType, true);
        }
        const currentBlockedStreams = isTeacher
          ? this.mediaStore.teacherMedia.blockedStreams[mediaType]
          : this.mediaStore.remoteMedia.get(peerId).blockedStreams.currentBlockedStreams[mediaType];
        const { consumerId, producerId } = currentBlockedStreams;
        if (consumerId && producerId) {
          await this.unconsume(roomId, peerId, consumerId, producerId, isTeacher);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async resumeRemoteMediaLocally(roomId, peerId, mediaType, isTeacher, scalabilityLevel) {
    console.log('Mediasoup resumeRemoteMediaLocally', {
      peerId,
      mediaType,
      isTeacher,
      scalabilityLevel,
    });
    try {
      let remoteMedia;
      if (isTeacher) {
        remoteMedia = this.mediaStore.teacherMedia;
      } else {
        remoteMedia = this.mediaStore.remoteMedia.get(peerId);
      }

      const setBlockingStreamsFlag = flag => {
        if (isTeacher) {
          this.mediaStore.setTeacherStreamsBlockingFlag(mediaType, flag);
        } else {
          this.mediaStore.setRemoteMediaStreamsBlockingFlag(peerId, mediaType, flag);
        }
      };

      const removeBlockedStreams = streamsBlockingFlag => {
        if (isTeacher) {
          this.mediaStore.removeTeacherBlockedStreams(mediaType, streamsBlockingFlag);
        } else {
          this.mediaStore.removeRemoteMediaBlockedStreams(peerId, mediaType, streamsBlockingFlag);
        }
      };

      if (remoteMedia) {
        if (remoteMedia.blockedStreams.currentBlockedStreams[mediaType]) {
          const { producerId } = remoteMedia.blockedStreams.currentBlockedStreams[mediaType];
          setBlockingStreamsFlag(false);
          if (producerId) {
            await this.consume(
              roomId,
              { producerId, mediaType },
              peerId,
              isTeacher,
              scalabilityLevel,
            );
          }
        }
        removeBlockedStreams(false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  getForcedBlockedMediaStreams() {
    return this.mediaStore.forcedBlockedMediaStreams;
  }

  setForcedBlockedMediaStreams(mediaType) {
    this.mediaStore.setForcedBlockedMediaStreams(mediaType);
  }

  removeForcedBlockedMediaStreams(mediaType) {
    this.mediaStore.removeForcedBlockedMediaStreams(mediaType);
  }

  resetForcedBlockedMediaStreams() {
    this.mediaStore.resetForcedBlockedMediaStreams();
  }

  setNewMediaBlockedStreams(blockedStreamsData, isSetAsDefaultBlockedStreams) {
    this.mediaStore.setNewMediaBlockedStreams(blockedStreamsData);
    if (isSetAsDefaultBlockedStreams) {
      this.mediaStore.setDefaultNewMediaBlockedStreams(blockedStreamsData);
    }
  }

  _onProducerEvents = producer => {
    producer.on('trackended', () => {
      console.log('Mediasoup producer trackended EVENT');
    });
    producer.on('transportclose', () => {
      console.log('Mediasoup producer transportclose EVENT');
    });
  };

  _onConsumerEvents = consumer => {
    consumer.on('trackend', () => {
      console.log('Mediasoup consumer trackend EVENT');
    });

    consumer.on('transportclose', () => {
      console.log('Mediasoup consumer transportclose EVENT');
    });
  };

  async _loadDevice(rtpCapabilities) {
    const { Device } = mediasoupClient;
    const device = new Device();
    try {
      await device.load({ routerRtpCapabilities: rtpCapabilities });
    } catch (e) {
      console.error(e);
    }
    this.device = device;
  }

  _initTransports(roomId, transports) {
    // to do transport должен иметь поле id, а не transportId
    const [sendTransport, receiveTransport] = transports;

    const producerTransport = this.device.createSendTransport({
      ...sendTransport,
      id: sendTransport.transportId,
    });
    this._onTransportEvents(roomId, producerTransport);
    const consumerTransport = this.device.createRecvTransport({
      ...receiveTransport,
      id: receiveTransport.transportId,
    });
    this._onTransportEvents(roomId, consumerTransport);
    this.producerTransport = producerTransport;
    this.consumerTransport = consumerTransport;
  }

  _onTransportEvents(roomId, transport) {
    transport.on(this.transportEvents.connect, async (data, callback, errCallback) => {
      const { dtlsParameters } = data;
      this.socketRequest(this.transportActions.connectTransport, {
        roomId,
        dtlsParameters,
        transportId: transport.id,
      })
        .then(() => callback())
        .catch(e => errCallback(e));
    });

    transport.on(this.transportEvents.produce, async (data, callback, errCallback) => {
      const { kind, rtpParameters, appData } = data;
      const { mediaType } = appData;
      let action;
      switch (mediaType) {
        case mediaTypes.audioType:
          action = this.videoConferenceActions.startStreamByMicrophone;
          break;
        case mediaTypes.videoType:
          action = this.videoConferenceActions.startStreamByCamera;
          break;
        case mediaTypes.screenType:
          action = this.videoConferenceActions.startShareScreen;
          break;
        // no default
      }
      try {
        const response = await this.socketRequest(action, {
          roomId,
          kind,
          rtpParameters,
          producerTransportId: transport.id,
        });
        const { producerId } = response.data.payload.producer;
        callback({ id: producerId });
      } catch (e) {
        errCallback(e);
      }
    });

    transport.on(this.transportEvents.connectionstatechange, state => {
      console.log(
        `${
          transport.direction === 'send' ? 'producerTransport' : 'consumerTransport'
        } connectionstatechange EVENT`,
        state,
      );
    });
  }

  _defineStore() {
    this.mediaStore = store.getStore('mediaStore');
  }
}

export default Mediasoup;
