import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Button from '../../components/Form/Button/Button';
import navigation from '../../constants/navigation';
import { ReactComponent as HomeIcon } from './assets/homeIcon.svg';
import logo from './assets/logo.png';
import { useStore } from '../../store';

function Error(props) {
  const { type, hintMessage } = props;
  const {
    globalPropsStore: { removeGlobalError },
  } = useStore();
  const history = useHistory();

  const onHomeClick = () => {
    removeGlobalError();
    history.push(navigation.home.path);
  };

  const errorHint = useMemo(() => {
    if (!hintMessage) {
      return (
        <>
          Упс, кажется, вы попали
          <br className="error__line-break" /> не туда, или такой страницы
          <br className="error__line-break" /> не существует...
        </>
      );
    }
    return hintMessage;
  }, [hintMessage]);

  return (
    <div className="error">
      <div className="error__title">
        <h1 className="error__error-number">{type}</h1>
        <p className="error__hint">{errorHint}</p>
        <Button
          classname="error__button"
          text="На главную"
          icon={<HomeIcon />}
          onClick={onHomeClick}
        />
      </div>
      <div className="error__image-container">
        <img className="error__image" src={logo} alt="logo403" />
      </div>
    </div>
  );
}

Error.propTypes = {
  type: PropTypes.oneOf([403, 404, '403', '404']),
  hintMessage: PropTypes.string,
};

Error.defaultProps = {
  type: 404,
  hintMessage: '',
};

export default Error;
