import React, { useEffect } from 'react';
import Button from '../../../Form/Button/Button';
import Modal from '../../Modal';
import { lesson } from '../../../../Lesson/Lesson';

function ReloadApp() {
  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (lesson) {
      lesson.exitFromLesson(true);
    }
  }, []);

  return (
    <Modal title="Кажется, что-то пошло не так." size="reload-app">
      <div className="modalContent reload-app-modal">
        <p>Попробуйте перезагрузить страницу</p>
        <Button text="Перезагрузить" color="primary" justifyContent="center" onClick={reload} />
      </div>
    </Modal>
  );
}

export default ReloadApp;
