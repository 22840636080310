import React, { useEffect, useReducer } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../store';
import Modal from '../Modal';

const loadingDotsInitialState = '.';
const loadingDotsMaxLength = 3;
const loadingDotsActions = { increment: 'increment' };
function loadingDotsReducer(state, action) {
  const { type } = action;
  switch (type) {
    case loadingDotsActions.increment:
      if (state.length < loadingDotsMaxLength) {
        return `${state}.`;
      }
      return loadingDotsInitialState;
    default:
      return state;
  }
}

function ReloadSocketConnection() {
  const {
    modalWindowsStore: { reloadSocketConnection },
  } = useStore();
  const [loadingDots, loadingDotsDispatch] = useReducer(
    loadingDotsReducer,
    loadingDotsInitialState,
  );

  useEffect(() => {
    let intervalID;
    const intervalDelay = 600;
    if (reloadSocketConnection) {
      intervalID = setInterval(() => {
        loadingDotsDispatch({ type: loadingDotsActions.increment });
      }, intervalDelay);
    }
    return () => clearInterval(intervalID);
  }, [reloadSocketConnection]);

  if (!reloadSocketConnection) {
    return null;
  }

  return (
    <Modal title="Соединение потеряно" size="reload-app">
      <div className="modalContent reload-app-modal">
        <p>Пытаемся восстановить соединение{loadingDots}</p>
      </div>
    </Modal>
  );
}

export default observer(ReloadSocketConnection);
