import apiURLs from '../../constants/apiURLs';

class ChatEndpoint {
  constructor(axios, setBearer, removeBearer) {
    this.axios = axios;
    this.setBearer = setBearer;
    this.removeBearer = removeBearer;
  }

  sendFile = async formData => {
    try {
      const response = await this.axios({
        method: 'POST',
        url: apiURLs.chatAttachment,
        data: formData,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  deleteFile = async id => {
    try {
      const response = await this.axios({
        method: 'DELETE',
        url: apiURLs.fileDelete(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  getFile = async (hash, code) => {
    try {
      const response = await this.axios({
        method: 'GET',
        url: apiURLs.getFile(hash, code),
      });
      console.log('Get file', response);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}
export default ChatEndpoint;
